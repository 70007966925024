import { IcInfo } from '@clc-v2/icons';
import styles from './level-information.module.scss';
import { Tooltip } from 'react-tooltip';

/* eslint-disable-next-line */
export interface LevelInformationProps { }

export function LevelInformation(props: LevelInformationProps) {
  return (
    <div className={styles['container']}>
      <a className="cursor-pointer" data-tooltip-id="level-icon"><IcInfo size={20} /></a>
      <Tooltip id="level-icon" place="bottom">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Level up to unlock more advanced hand topics.</p>
          <p>Flop - Level 5</p>
          <p>Turn - Level 10</p>
          <p>River - Level 15</p>
          <p>Heads Up - Level 20</p>
          <p>*All topics are available in Pick Your Path.</p>
        </div>
      </Tooltip>
    </div>
  );
}

export default LevelInformation;
