import styles from './ic-info.module.scss';

/* eslint-disable-next-line */
export interface IcInfoProps {
  size: number;
}

export function IcInfo(props: IcInfoProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23 12.0002C23 18.0754 18.0751 23.0002 12 23.0002C5.92487 23.0002 1 18.0754 1 12.0002C1 5.92511 5.92487 1.00024 12 1.00024C18.0751 1.00024 23 5.92511 23 12.0002ZM24 12.0002C24 18.6277 18.6274 24.0002 12 24.0002C5.37258 24.0002 0 18.6277 0 12.0002C0 5.37283 5.37258 0.000244141 12 0.000244141C18.6274 0.000244141 24 5.37283 24 12.0002ZM11.0917 17.5978H12.48V9.79963H11.0917V17.5978ZM11.0769 8.54424H12.4948V6.46178H11.0769V8.54424Z" fill="#F2F2F2"/>
    </svg>    
  );
}

IcInfo.defaultProps = {
  size: 24,
}

export default IcInfo;
