import classNames from 'classnames';
import styles from './tabs.module.scss';
import { Fragment, ReactNode, useState } from 'react';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface TabsProps<D> {
  activeValue: D;
  options: Array<{
    label: ReactNode;
    value: D;
    inner_value?: string;
    description?: string;
  }>,
  onTabChangeHandler: (newValue: D) => Promise<boolean>;
  activeRadioValue?: D;
  onClickRadioBoxHandler?: (newValue: D) => Promise<boolean>;
}

export function Tabs(props: TabsProps<string>) {
  const [loading, setLoading] = useState<boolean>(false);
  const onSwitchChangeHandler = lodash.debounce((newTab: string) => {
    setLoading(true);
    props.onTabChangeHandler(newTab).then((value) => {
      setLoading(false);
    });
  }, 100);
  return (
    <div className={classNames(
      styles['container'],
      'w-full h-[60px]',
      'flex items-center gap-0',
      'border-b border-solid border-primary-dark-grey',
      'overflow-y-hidden overflow-x-auto',
    )}>
      {props.options.map((option, id) => (
        <Fragment key={id}>
          <button title={option.description} className={classNames(
            'h-full min-w-0 md:min-w-[160px] flex justify-center items-center gap-4 px-1 md:px-2',
            {
              'border-b-2 border-solid border-primary-golden -mb-[1px]': props.activeValue === option.value
            },
            {
              'text-white': props.activeValue === option.value,
              'text-secondary-grey': props.activeValue !== option.value,
            },
            'whitespace-nowrap',
            'font-inter text-sm md:text-lg font-normal leading-[27px]'
          )} disabled={loading} onClick={() => onSwitchChangeHandler(option.value)}>
            <span className='px-4 font-inter font-normal text-lg leading-7'>
              {option.label}
            </span>
            {(props.activeRadioValue && option.inner_value) && <div className='p-2' onClick={(e) => {
              e.stopPropagation();
              if (props.onClickRadioBoxHandler && option.inner_value) {
                props.onClickRadioBoxHandler(option.inner_value)
              }
            }}>
              <input type="radio" checked={props.activeRadioValue === option.inner_value} />
            </div>}
          </button>
        </Fragment>
      ))}
    </div>
  );
}

export default Tabs;
