import classNames from 'classnames';
import styles from './author.module.scss';

/* eslint-disable-next-line */
export interface AuthorProps {
  author?: {
    picture: string;
    thumbnail_picture: string;
    given_name: string;
    family_name: string;
  };
}

export function Author(props: AuthorProps) {
  return (
    <div className={classNames(styles['container'], "flex items-center gap-2")}>
      <div className="w-8 h-8 rounded-full overflow-hidden border-2 border-color-2">
        <img className="min-w-full min-h-full" src={props.author?.thumbnail_picture === '' ? props.author?.picture : props.author?.thumbnail_picture} alt="Avatar" />
      </div>
      <div className="flex flex-col gap-0">
        <span className="text-white text-xs leading-[14px] tracking-[2px] font-inter font-light">{props.author?.given_name}</span>
        <span className="text-primary-golden text-xs leading-[14px] tracking-[3.2px] font-inter font-bold">{props.author?.family_name}</span>
      </div>
    </div>
  );
}

export default Author;
