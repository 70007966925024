import Avatar from 'react-avatar';
import Card from '../card/card';
import { IcChip } from '@clc-v2/icons';
import { Level, User } from '@prisma/client';
import styles from './card-performance.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import LevelInformation from '../level-information/level-information';
import PlaqueImg from '../topbar/plaque.png';

export interface CardPerformanceProps {
  user?: User,
  totalRank: number;
  totalTickets: number;
  totalPoints: number;
  my_level?: Level;
  levels?: Level[];
  plaque_available?: boolean;
  avatarClickHandler?: () => void;
  checkPlaquesHandler?: () => void;
}

export function CardPerformance(props: CardPerformanceProps) {
  const [next_level, set_next_level] = useState<Level>();
  useEffect(() => {
    if (props.levels && props.my_level) {
      const index = props.levels.map((_level) => _level.id).indexOf(props.my_level.id);
      if (props.levels.length - 1 > index) {
        set_next_level(props.levels[index + 1])
      } else {
        set_next_level(undefined);
      }
    }
  }, [props.levels, props.my_level]);
  return (
    <Card size='medium' className={classNames(
      styles['container'],
      'w-full flex items-center gap-2 md:gap-4 h-[196px]',
    )}>
      <div className='flex grow-0 shrink-0 relative'>
        {props.user ? (
          <Avatar
            title="Update Profile"
            className='cursor-pointer'
            size='152'
            textSizeRatio={3}
            textMarginRatio={.15}
            maxInitials={2}
            name={`${props.user.given_name} ${props.user.family_name}`}
            src={props.user.picture}
            round={true}
            onClick={props.avatarClickHandler}
          />
        ) : (
          <div className="animate-pulse flex items-center justify-center w-full h-full bg-gray-300 rounded dark:bg-gray-700">
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
        )}
        {props.plaque_available && (
          <div className='absolute left-2 top-2 animate-pulse' onClick={props.checkPlaquesHandler}>
            <img className='w-8 h-8' src={PlaqueImg} alt="Plaque" />
          </div>
        )}
      </div >

      <div className='w-[calc(100%-152px-0.5rem)] md:w-[calc(100%-152px-1rem)] h-[152px] grow-0 shrink-0 flex flex-col justify-between'>
        <div className='flex flex-col gap-1.5 text-white text-xl font-normal cursor-default'>
          {props.user ? (
            <span className='font-krona-one font-normal text-lg capitalize tracking-[-5%] break-words line-clamp-1'>{props.user.user_name}</span>
          ) : (
            <div className="animate-pulse w-full">
              <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 mb-2" />
              <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
            </div>
          )}
        </div>
        <div className='relative'>
          <div className='w-full flex flex-col gap-y-1'>
            <div className='flex items-start'>
              <div className='flex flex-col'>
                <span className='font-inter font-light text-xs leading-[14px] tracking-[2px] text-primary-golden uppercase'>Rank {props.totalRank > 0 ? props.totalRank : '-'}</span>
              </div>
            </div>
            <div className='w-fit px-3 h-7 rounded-full flex items-center gap-3 bg-primary-carbon'>
              <span className='text-white text-xs font-light font-inter leading-[12px] tracking-[2px]'>{props.totalPoints}</span>
              <IcChip />
            </div>
          </div>
        </div>
        <div className='relative' title={next_level ? `${props.totalPoints}/${next_level.init_point} XP` : ''}>
          <div className='w-[calc(100%-8px)] flex flex-col gap-y-1'>
            {/* <div className='w-fit px-3 h-7 rounded-full flex items-center gap-3 bg-primary-carbon'>
              <span className='text-white text-xs font-light font-inter leading-[12px] tracking-[2px]'>{props.totalTickets}</span>
              <IcMoney />
            </div> */}
            {props.my_level && (
              <div className='flex items-start gap-4 z-10'>
                <div className='w-fit h-7 rounded-full flex items-center gap-3 font-inter font-light text-xs leading-[14px] tracking-[2px] text-primary-golden'>{props.my_level.title}</div>
                <LevelInformation />
              </div>
            )}
          </div>
          {props.my_level && <div className='w-full mt-2'>
            {next_level ? <div className='w-full h-2 bg-gradient-to-b from-gray-400 to-gray-100 relative border-[1px] border-white rounded-full overflow-hidden'>
              <div
                className='absolute left-0 bottom-0 h-1.5 bg-gradient-to-r from-color-5 to-primary-golden shadow-md rounded-full border-r-red-500 border-r-[3px]'
                style={{
                  width: `${((props.totalPoints - props.my_level.init_point) / (next_level.init_point - props.my_level.init_point)) * 100}%`,
                }}
              >
                {/* {props.user ? <img className='h-6 w-auto absolute right-0 bottom-0 transform translate-x-1/2' src={props.user.picture} /> : null} */}
              </div>
            </div> : null}
          </div>}
        </div>
      </div>
    </Card >
  );
}

export default CardPerformance;
