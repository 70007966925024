import { LoginEmailForm, LoginSocial } from "@clc-v2/uis"
import { API_BACKEND_ENDPOINT, COOKIE_DOMAIN, NX_AUTH_REDIRECT_CACHE_TIME } from "../config"
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";
import { useSearchParams } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useBus } from "react-bus";

export const EncryptionKey = process.env.NX_AUTH_ENCRYPTION_KEY ?? '';
export const AuthCookieName = process.env.NX_AUTH_COOKIE_NAME ?? '';
export const defaultRedirectUrl = process.env.NX_DOMAIN ?? window.location.href;

export const LoginForm = () => {
    const [url_search_params, set_url_search_params] = useSearchParams();
    const bus = useBus();
    const login_handler = (param: { email: string, password: string, remember_me: boolean, }) => {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(
                `${API_BACKEND_ENDPOINT}/auth/login-email-and-password`,
                param
            ).then((res: { data: { accessToken: string } }) => {
                const encryptedAccessToken = CryptoJS.AES.encrypt(res.data.accessToken, EncryptionKey).toString();
                Cookie.set(AuthCookieName, encryptedAccessToken, {
                    domain: COOKIE_DOMAIN
                })
                setTimeout(() => {
                    resolve(true);
                    const redirectUrl = url_search_params.get('redirect') ?? defaultRedirectUrl;
                    window.location.href = redirectUrl
                }, NX_AUTH_REDIRECT_CACHE_TIME);
            }).catch((err: AxiosError<{ status: string, message: string[], }>) => {
                if (err.response) {
                    // if axios error
                    reject(err.response.data.message);
                } else {
                    reject([err.message]);
                }
            })
        })
    }
    const google_login_handler = useGoogleLogin({
        flow: 'implicit',
        onSuccess: async (codeResponse) => {
            axios.post(
                `${API_BACKEND_ENDPOINT}/auth/login-google`,
                codeResponse
            ).then((res: { data: { accessToken: string } }) => {
                const encryptedAccessToken = CryptoJS.AES.encrypt(res.data.accessToken, EncryptionKey).toString();
                Cookie.set(AuthCookieName, encryptedAccessToken, {
                    domain: COOKIE_DOMAIN
                })
                setTimeout(() => {
                    const redirectUrl = url_search_params.get('redirect') ?? defaultRedirectUrl;
                    window.location.href = redirectUrl
                }, 500);
            }).catch((err: AxiosError<{ statusCode: number, message: string[], error: string }>) => {
                if (err.response) {
                    // if axios error
                    if (err.response.data.message.indexOf("You have to register google account") >= 0) {
                        set_url_search_params((_prev => {
                            _prev.set("tab", 'register')
                            return _prev
                        }));
                        toast.warn(err.response.data.message[0], {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    } else {
                        toast.warn(err.response.data.message.join(','), {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                } else {
                    // if network error
                    toast.warn(err.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return (
        <div className="w-full">
            <div className="hidden items-center gap-2">
                <div className="grow shrink border border-solid border-primary-golden"></div>
                <div className="grow-0 shrink-0 text-sm text-text-op70 font-normal font-inter">Or better yet...</div>
                <div className="grow shrink border border-solid border-primary-golden"></div>
            </div>
            <LoginEmailForm
                forgot_passwordHandler={() => {
                    bus.emit('open-forget-password');
                }}
                login_handler={login_handler}
            />
            <div className="w-full h-4" />
            <LoginSocial
                type="login"
                google_handler={google_login_handler}
            />
        </div>
    )
}