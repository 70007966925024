import styles from './ic-home.module.scss';

/* eslint-disable-next-line */
export interface IcHomeProps {
  size: number;
}

export function IcHome(props: IcHomeProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 16V8.41667L9 2L16 8.41667V16" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcHome.defaultProps = {
  size: 18,
}

export default IcHome;
