import classNames from 'classnames';
import styles from './badge-coming-soon.module.scss';
import ComingSoon from './coming-soon.png';

/* eslint-disable-next-line */
export interface BadgeComingSoonProps { }

export function BadgeComingSoon(props: BadgeComingSoonProps) {
  return (
    <div
      className={classNames(
        styles['container'],
        'absolute top-0 right-0',
        'z-30'
      )}
    >
      <img src={ComingSoon} alt="Coming Soon" />
    </div>
  );
}

export default BadgeComingSoon;
