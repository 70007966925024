import moment from 'moment';

export function utilities(): string {
  return 'utilities';
}

export const embedVideo = (url: string) => {
  let host = url !== "" ? new URL(url).host : "";

  switch (host) {
    case "player.vimeo.com":
      let playerVimeoID = new URL(url).pathname;
      const newPlayerVimeoID = playerVimeoID.split("/");
      return `https://player.vimeo.com/video/${newPlayerVimeoID[2]}`;
    case "vimeo.com":
      let vimeoID = new URL(url).pathname;
      return `https://player.vimeo.com/video${vimeoID}`;
    case "www.youtube.com":
      let youtubeID = new URL(url).searchParams.get("v");
      return `https://www.youtube.com/embed/${youtubeID}`;
    default:
      return url;
  }
};

export const expired = (unix_timestamp: number, cache_minutes: number) => {
  return moment.unix(unix_timestamp).diff(moment(), 'minutes') < (-1 * cache_minutes);
}