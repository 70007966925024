import classNames from 'classnames';
import styles from './card-video.module.scss';
import { IcWatch } from '@clc-v2/icons';
import Tags from '../tags/tags';
import Marquee from "react-fast-marquee";
import { LibraryType } from '@prisma/client';

const label = {
  [LibraryType.webinar]: 'Webinar',
  [LibraryType.lesson]: 'Lesson',
  [LibraryType.online_session]: 'Online Session',
  [LibraryType.quiz]: 'Quiz',
}

/* eslint-disable-next-line */
export interface CardVideoProps {
  // titleClassName: string;
  // gapClassName: string;
  // imgClassName: string;
  // paddingClassName: string;
  size: 'lg' | 'md';
  type?: LibraryType;
  tags: Array<{
    id: string;
    name: string;
  }>;
  videoThumbnail: string;
  videoUrl?: string;
  title: string;
  length: number; // seconds
  watched?: boolean;
  watched_once?: boolean;
  // last_claimed?: string | null;
  difficulty?: number;
  on_change_watched?: (_watched: boolean) => void;
  on_click?: () => void;
  // on_claim?: () => void;
}

export function CardVideo(props: CardVideoProps) {
  return (
    <div
      className={classNames(
        styles['container'],
        'w-full',
        'rounded-small',
        'overflow-hidden',
        'border border-solid border-primary-dark-grey hover:border-primary-golden',
        'bg-primary-carbon',
        'flex flex-col',
        'transition-all duration-200',
        'relative'
      )}
      onClick={props.on_click}
    >
      {/* {props.watched_once && (
        <button className={classNames(
          'absolute right-1 top-1 px-2 py-1 rounded-md z-20',
          {
            'bg-primary-golden': !props.last_claimed,
            'bg-green': props.last_claimed
          }
        )} onClick={(e) => {
          e.stopPropagation()
          if (!props.last_claimed) {
            props.on_claim?.()
          }
        }}>
          {props.last_claimed ? (
            <span className='text-yellow-300'>Claimed</span>
          ) : (
            <span className='text-red-500-'>Claim</span>
          )}
        </button>
      )} */}
      <div
        className={classNames(
          'flex flex-col border-b border-solid border-primary-dark-grey cursor-pointer',
          {
            "gap-[30px] p-2 lg:p-4": props.size === 'lg',
            "gap-[17px] p-2 lg:p-3": props.size === 'md',
          },
          // props.gapClassName,
          // props.paddingClassName
        )}
      >
        <div
          className={classNames(
            'w-full rounded-small flex justify-center items-center relative overflow-hidden border border-primary-dark-grey',
            {
              "h-36 lg:h-[191px]": props.size === 'lg',
              "h-36 lg:h-[144px]": props.size === 'md',
            }
            // props.imgClassName
          )}
        >
          {props.type && (
            <span className={classNames(
              'absolute left-2 top-2',
              'px-2 py-1',
              'border border-white border-opacity-5',
              'rounded-full',
              'text-primary-golden text-[10px] font-light font-inter leading-[12px] tracking-[2px] uppercase',
              'bg-gradient-to-r from-color-2 to-color-3',
              'backdrop-blur-md'
            )}>{label[props.type]}</span>
          )}
          <img className='min-w-full min-h-full w-full h-full object-cover' src={props.videoThumbnail} alt="" />
        </div>
        <div className='w-full flex flex-col gap-2'>
          <h3
            className={classNames(
              'text-white font-inter font-medium',
              {
                "h-[87px] text-2xl leading-[28px] tracking-[-0.2px] line-clamp-3": props.size === 'lg',
                "h-[38px] text-base leading-[19.2px] line-clamp-2 ": props.size === 'md',
              }
              // props.titleClassName
            )}
          >{props.title}</h3>
          <div
            className={classNames(
              'h-7 flex gap-1 text-white',
              {
                'hidden': true
              }
            )}
            title={props.tags.map((tag) => tag.name).join(",")}
          >
            <Marquee autoFill>
              {/* {props.tags.map((tag) => tag.name).join(', ')} */}
              <Tags onTagHandler={() => { }} tags={props.tags} /><span className='ml-1' />
            </Marquee>
          </div>
        </div>
      </div>
      <div
        className='w-full h-[47px] px-2 lg:px-5 flex justify-between items-center bg-black'
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className='flex flex-col gap-0'>
          <div className='flex items-center gap-2 cursor-pointer'>
            <IcWatch />
            <span className='text-text-op70 text-[10px] leading-[12px] tracking-[2px] font-light'>{props.length >= 60 ? `${(props.length / 60).toFixed(0)} MIN` : `${props.length} SEC`}</span>
          </div>
          {props.difficulty !== undefined && <div>
            <span className='text-secondary-grey text-xs leading-[14px] tracking-[2px] font-inter'>{props.difficulty === 1 ? "Beginner" : props.difficulty === 2 ? "Intermediate" : props.difficulty === 3 ? "Advanced" : ""}</span>
          </div>}
        </div>
        {props.on_change_watched && (
          <div
            className='flex items-center gap-2'
            title='If you have seen this video, click Yes. If not, click No'
          >
            <>
              <label htmlFor={props.title} className='cursor-pointer text-secondary-grey hover:text-primary-golden text-xs leading-[14px] tracking-[2px] font-inter'>Watched</label>
              <div className='relative flex justify-center items-center'>
                <input
                  id={props.title}
                  className={classNames(
                    "before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all",
                    "before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity",
                    "checked:border-primary-golden checked:bg-primary-golden checked:before:bg-primary-golden hover:before:opacity-10"
                  )}
                  type="checkbox"
                  checked={props.watched}
                  onChange={(e) => props.on_change_watched?.(e.target.checked)}
                />
                <span
                  className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                    stroke="currentColor" strokeWidth="1">
                    <path fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"></path>
                  </svg>
                </span>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardVideo;
