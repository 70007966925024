import styles from './ic-fly.module.scss';

/* eslint-disable-next-line */
export interface IcFlyProps {
  size: number;
}

export function IcFly(props: IcFlyProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 19} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2899 1.91504L2.41492 7.54004L9.34689 8.92697L10.6649 15.79L16.2899 1.91504Z" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcFly.defaultProps = {
  size: 19,
}

export default IcFly;
