import { Button, InputIcon } from '@clc-v2/uis';
import axios, { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useListener } from 'react-bus';
import { Modal } from 'react-responsive-modal';
import { API_BACKEND_ENDPOINT } from '../config';
import { IcCheck, IcEmail, IcPassword } from '@clc-v2/icons';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

enum STEP {
    forget_password,
    reset_password,
}

export const ForgotPassword = () => {
    const [url_search_params, set_url_search_params] = useSearchParams();
    const [open, set_open] = useState<boolean>(false);
    const [email, set_email] = useState<string>('');
    const [step, set_step] = useState<STEP>(STEP.forget_password);
    const [password, set_password] = useState<string>('');
    const [code, set_code] = useState<string>('');
    const [error, set_error] = useState<string[]>([]);
    const on_open_forget_password = useCallback(() => {
        set_open(true);
        set_email('')
        set_password('')
        set_code('');
    }, []);
    const forgot_password_handler = () => {
        if (email) {
            set_step(STEP.reset_password);
            axios.post(`${API_BACKEND_ENDPOINT}/auth/forgot-password`, { email }).then((res: { data: { accessToken: string } }) => {
                toast.success("Verification code has been sent to your email address. If you do not receive it in your inbox, please check the spam folder.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).catch((err: AxiosError<{ status: string, message: string[], }>) => {
                if (err.response) {
                    // if axios error
                    set_error(err.response.data.message);
                } else {
                    set_error([err.message]);
                }
            })
        }
    }
    const reset_password_handler = () => {
        if (email && password && code && step === STEP.reset_password) {
            axios.post(`${API_BACKEND_ENDPOINT}/auth/reset-password`, {
                email,
                new_password: password,
                reset_password_token: code,
            }).then((res: { data: { accessToken: string } }) => {
                toast.success("Your password is updated successfully", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                set_open(false);
            }).catch((err: AxiosError<{ status: string, message: string[], }>) => {
                if (err.response) {
                    // if axios error
                    set_error(err.response.data.message);
                } else {
                    set_error([err.message]);
                }
            })
        }
    }
    useListener('open-forget-password', on_open_forget_password);
    useEffect(() => {
        set_step(STEP.forget_password);
    }, []);
    useEffect(() => {
        if (step === STEP.forget_password) {
            set_password('');
            set_code('');
        }
    }, [step]);
    useEffect(() => {
        if (url_search_params.get('reset-password') === 'true') {
            set_open(true);
        }
    }, [url_search_params.get('reset-password')])
    return (
        <Modal center showCloseIcon={false} classNames={{
            modal: "!bg-black w-96 border border-primary-golden"
        }} open={open} onClose={() => set_open(false)}>
            <h2 className="w-full text-center text-white text-lg mb-6">Did you forget password?</h2>
            <InputIcon className='mt-2' prefix={<IcEmail />} type="email" placeholder='Email' value={email} onChange={(e) => set_email(e.target.value)} />
            {step === STEP.reset_password && (
                <>
                    <InputIcon className='mt-2' prefix={<IcPassword />} type="password" placeholder='New Password' value={password} onChange={(e) => set_password(e.target.value)} />
                    <InputIcon className='mt-2' prefix={<IcCheck />} type="email" placeholder='Code' value={code} onChange={(e) => set_code(e.target.value)} />
                </>
            )}
            {error && <p>{error}</p>}
            <div className='w-full flex justify-center items-center gap-4 mt-2'>
                <Button type='primary' onClick={forgot_password_handler}>
                    {step === STEP.reset_password ? "Resend Code" : "Send Request"}
                </Button>
                {step === STEP.reset_password && (
                    <Button type='primary' onClick={reset_password_handler}>Reset Password</Button>
                )}
            </div>
        </Modal>
    )
}