import styles from './ic-tiktok.module.scss';

/* eslint-disable-next-line */
export interface IcTiktokProps {
  size: number;
}

export function IcTiktok(props: IcTiktokProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0241 3.15855C11.4323 2.58253 11.1062 1.84278 11.1064 1.07703H8.43118V10.2298C8.41055 10.7251 8.16528 11.1943 7.74702 11.5385C7.32876 11.8827 6.77015 12.0751 6.18882 12.0751C4.95942 12.0751 3.93781 11.2189 3.93781 10.156C3.93781 8.88641 5.375 7.93423 6.85547 8.32543V5.99295C3.86855 5.65341 1.25391 7.6316 1.25391 10.156C1.25391 12.614 3.64345 14.3633 6.18017 14.3633C8.8987 14.3633 11.1064 12.4811 11.1064 10.156V5.51317C12.1912 6.17737 13.4937 6.53374 14.8293 6.53179V4.25097C14.8293 4.25097 13.2016 4.3174 12.0241 3.15855Z" fill="white"/>
    </svg>
  );
}

IcTiktok.defaultProps = {
  size: 16,
}

export default IcTiktok;
