import styles from './ic-google.module.scss';

/* eslint-disable-next-line */
export interface IcGoogleProps {
  size: number;
}

export function IcGoogle(props: IcGoogleProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 19} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.14 9.20454C18.14 8.56636 18.0827 7.95273 17.9764 7.36364H9.5V10.845H14.3436C14.135 11.97 13.5009 12.9232 12.5477 13.5614V15.8195H15.4564C17.1582 14.2527 18.14 11.9455 18.14 9.20454Z" fill="#4285F4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 18C11.93 18 13.9673 17.1941 15.4564 15.8195L12.5477 13.5614C11.7418 14.1014 10.7109 14.4205 9.5 14.4205C7.15591 14.4205 5.17182 12.8373 4.46409 10.71H1.45728V13.0418C2.93818 15.9832 5.98182 18 9.5 18Z" fill="#34A853" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.46409 10.71C4.28409 10.17 4.18182 9.59318 4.18182 9C4.18182 8.40682 4.28409 7.83 4.46409 7.29V4.95818H1.45727C0.847727 6.17318 0.5 7.54773 0.5 9C0.5 10.4523 0.847727 11.8268 1.45727 13.0418L4.46409 10.71Z" fill="#FBBC05" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 3.57955C10.8214 3.57955 12.0077 4.03364 12.9405 4.92545L15.5218 2.34409C13.9632 0.891818 11.9259 0 9.5 0C5.98182 0 2.93818 2.01682 1.45728 4.95818L4.46409 7.29C5.17182 5.16273 7.15591 3.57955 9.5 3.57955Z" fill="#EA4335" />
    </svg>
  );
}

IcGoogle.defaultProps = {
  size: 24,
}

export default IcGoogle;
