import styles from './ic-circle-icon.module.scss';

/* eslint-disable-next-line */
export interface IcCircleIconProps {}

export function IcCircleIcon(props: IcCircleIconProps) {
  return (
    <svg
      className={styles['container']}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="5 0 15 33"
    >
      <g filter="url(#filter0_d_103_4269)">
        <circle cx="16.373" cy="16.567" r="3.499" fill="#fff"></circle>
        <circle
          cx="16.373"
          cy="16.567"
          r="4.811"
          stroke="#759A47"
          strokeWidth="2.624"
        ></circle>
      </g>
      <defs>
        <filter
          id="filter0_d_103_4269"
          width="31.487"
          height="31.487"
          x="0.63"
          y="0.9"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="4.811"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.460174 0 0 0 0 0.604167 0 0 0 0 0.27691 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_103_4269"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_103_4269"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default IcCircleIcon;
