import classNames from 'classnames';
import styles from './leaderboard-item.module.scss';
import { IcTicket } from '@clc-v2/icons';
import numeral from 'numeral';
// import Avatar from '../avatar/avatar';
import Avatar from 'react-avatar';

/* eslint-disable-next-line */
export interface LeaderboardItemData {
  rank: number;
  correct: number;
  total: number;
  earned_chips: number;
  chips: number;
  earned_tickets: number;
  user?: {
    id: string;
    picture: string;
    thumbnail_picture: string;
    user_name: string;
    given_name: string | null;
    family_name: string | null;
  },
}
export interface LeaderboardItemProps extends LeaderboardItemData {
  you?: boolean;
}

export function LeaderboardItem(props: LeaderboardItemProps & {
  prev_rank: number;
}) {
  return (
    <div className={classNames(
      styles['container'],
      'w-full h-14',
      'px-3 py-2',
      'rounded-medium',
      'flex items-center',
      'font-inter'
    )}>
      {props.rank > 0 && <div className='w-9 h-9 grow-0 shrink-0 rounded-full border border-solid border-color-3 flex justify-center items-center'>
        <span className='text-xs text-text-op70 font-normal leading-[12px]'>
          {props.rank < 10 ? `0${props.rank}` : props.rank}
        </span>
      </div>}
      {props.rank > 0 ? (
        <div className='grow shrink px-5 flex items-center gap-4'>
          {/* <div className='w-6 h-6 grow-0 shrink-0 rounded-full'> */}
          {/* <Avatar picture={props.user?.picture} /> */}
          <Avatar size={"28px"} textSizeRatio={1} name={`${props.user?.given_name} ${props.user?.family_name}`} src={props.user?.thumbnail_picture === '' ? props.user?.picture : props.user?.thumbnail_picture} round />
          {/* </div> */}
          <span className={classNames(
            'grow shrink',
            'text-lg font-normal break-all leading-[27px] font-inter',
            'line-clamp-2',
            {
              'text-text-op70': !props.you,
              'text-primary-golden': props.you,
            }
          )}>{props.you ? "YOU" : `${props.user?.user_name}`}</span>
        </div>
      ) : (
        <div className='grow shrink px-5 flex items-center gap-4'>
          {props.you ? "You didn't play" : "Did not play. refresh again"}
        </div>
      )}
      <div className='grow-0 shrink-0 px-1 md:px-5 flex items-center gap-1' title={`${props.correct} answers were correct of ${props.total} questions, and earned ${props.chips} XP`}>
        <span className='text-xs font-light text-white font-inter'>{numeral(props.chips).format('0,0')}</span>
        {/* <IcCheck size={20} /> */}
        <IcTicket size={20} />
      </div>
      {/* <div className='grow-0 shrink-0 w-20 px-1 md:px-5 flex items-center justify-end' title={props.you ? `You earned ${props.earned_tickets} tickets` : `${props.user?.user_name} earned ${props.earned_tickets} tickets & ${props.chips} chips`}>
        <span className='text-xs font-light text-white'>{numeral(props.earned_tickets).format('0,0')}</span>
        <IcMoney />
      </div> */}
    </div>
  );
}

export default LeaderboardItem;
