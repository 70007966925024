import styles from './ic-three-dots.module.scss';

/* eslint-disable-next-line */
export interface IcThreeDotsProps {
  size: number
}

export function IcThreeDots(props: IcThreeDotsProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 2.00024C4 3.10481 3.10457 4.00024 2 4.00024C0.895431 4.00024 0 3.10481 0 2.00024C0 0.895675 0.895431 0.000244141 2 0.000244141C3.10457 0.000244141 4 0.895675 4 2.00024ZM4 10.0002C4 11.1048 3.10457 12.0002 2 12.0002C0.895431 12.0002 0 11.1048 0 10.0002C0 8.89567 0.895431 8.00024 2 8.00024C3.10457 8.00024 4 8.89567 4 10.0002ZM2 20.0002C3.10457 20.0002 4 19.1048 4 18.0002C4 16.8957 3.10457 16.0002 2 16.0002C0.895431 16.0002 0 16.8957 0 18.0002C0 19.1048 0.895431 20.0002 2 20.0002Z" fill="#76746C" />
    </svg>
  );
}

IcThreeDots.defaultProps = {
  size: 16,
}

export default IcThreeDots;
