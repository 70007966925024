import classNames from 'classnames';
import styles from './card-event.module.scss';
import Divider from '../divider/divider';
import moment from 'moment';
import { dateFormatter } from '@clc-v2/utilities';

/* eslint-disable-next-line */
export interface CardEventProps {
  date: string;
  title: string;
  hosts: Array<{
    picture: string;
    thumbnail_picture: string;
    first_name: string;
    last_name: string;
  }>;
  onClick?: () => void;
}

export function CardEvent(props: CardEventProps) {
  return (
    <div
      className={classNames(
        styles['container'],
        'w-full h-[326px]',
        'rounded-large',
        'p-8',
        'flex flex-col',
        'border border-solid border-color-2',
        'bg-secondary-transparent-coal',
        'font-inter',
        {
          'cursor-pointer': !!props.onClick
        },
      )}
      onClick={props.onClick}
    >
      <span className='text-secondary-grey text-xs font-light leading-[14px] tracking-[2px]'>{dateFormatter(props.date)}</span>
      <p className={classNames(
        'text-white text-lg leading-[21px] tracking-[-0.2px] font-medium mt-2',
        'line-clamp-3',
        'grow-0 shrink-0'
      )}>{props.title} {props.title}</p>
      <div className='py-8'>
        <Divider />
      </div>
      <span className='uppercase text-secondary-grey text-xs font-light leading-[14px] tracking-[2px]'>Hosted By</span>
      <div className='h-[80px] grow-0 shrink-0 overflow-auto flex flex-col gap-2 mt-4 thin-scroller'>
        {
          props.hosts.map((host, id) => (
            <div className='flex items-center gap-3' key={id}>
              <img className='w-9 h-9 border-2 border-solid border-color-2 rounded-full overflow-hidden' src={host.thumbnail_picture === '' ? host.picture : host.thumbnail_picture} alt="Picture" />
              <div className='flex flex-col font-inter font-light text-[10px] leading-[12px] tracking-[2px]'>
                <span>{host.first_name.toUpperCase()}</span>
                <span>{host.last_name.toUpperCase()}</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default CardEvent;
