import { ClcLogo, Tabs } from "@clc-v2/uis"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { LoginForm } from "../components/LoginForm";
import { SignUpForm } from "../components/SignUpForm";
import { ForgotPassword } from "../components/ForgotPassword";

export const Auth = () => {
    const [url_search_params, set_url_search_params] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('');
    useEffect(() => {
        if (url_search_params.get('tab') === 'register') {
            setActiveTab('register');
        } else {
            setActiveTab('login');
        }
    }, [url_search_params.get('tab')]);
    return (
        <div className="w-full min-h-screen flex flex-col md:grid md:grid-cols-2 bg-black">
            <div className="grow-0 shrink-0 h-52 md:h-full flex flex-col justify-center items-center gap-12 px-12 lg:px-24 bg-primary-carbon">
                <div className="w-full flex justify-center items-center gap-4">
                    <ClcLogo className='w-56 h-24 md:w-80 md:h-36' />
                    {/* <span className='uppercase text-white'>Chip Leader AI</span> */}
                </div>
                <div className="hidden md:flex flex-col items-center px-6">
                    <h2 className="block text-white text-4xl font-medium font-inter leading-[57px] text-center">The fastest way to start crushing tournaments</h2>
                </div>
                {/* <div className="hidden md:block">
                    <img src={Img01} alt="img-01" />
                </div> */}
            </div>
            <div className="grow shrink flex justify-center items-start md:items-center py-6">
                <div className="w-[339px] max-w-sm h-fit flex flex-col gap-4">
                    <Tabs
                        activeValue={activeTab}
                        options={[
                            { label: "Log in", value: "login" },
                            { label: "Create account", value: "register" },
                        ]}
                        onTabChangeHandler={(newTab) => new Promise((resolve, reject) => {
                            setActiveTab(newTab);
                            set_url_search_params((_prev) => {
                                _prev.set("tab", newTab)
                                return _prev
                            })
                            resolve(true);
                        })}
                    />
                    {activeTab === 'login' && (<LoginForm />)}
                    {activeTab === 'register' && (<SignUpForm />)}
                </div>
            </div>
            <ForgotPassword />
        </div>
    )
}
