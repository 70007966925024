import { useEffect, useRef, useState } from "react";
import { IHand } from "../interfaces/hand.interface";
import { IChipPos } from "../utilities/handle-chip-pos";
import { CURRENCY_MODE } from "./controller";
import chip from "../assets/images/chip.png";
import dealer_chip from "../assets/images/chip-dealer.png";
import question_chip from "../assets/images/chip-question.png";
import { Roll, Rotate } from 'react-awesome-reveal';
import { numberWithCommas } from "../utilities/number-with-commas";
import { renderCard } from "../utilities/render-card";
import cardBack from '../assets/images/cardback.png';
import styles from './player-card.module.scss';
import classNames from "classnames";

export type POSITION_LABEL = "SB" | "BB" | "UTG" | "UTG+1" | "MP" | ("MP+1" | "LJ") | "HJ" | "CO" | "BTN"

export interface PropsPlayerCard {
  position_label: POSITION_LABEL;
  count_of_players: number;
  player: number;
  me: boolean;
  cards: string[];
  mp: number;
  chipPos: IChipPos;
  turn: boolean;
  dealer: number;
  action: string;
  amount: number;
  question_history: IHand[];
  display_amount: number;
  pot: number;
  bb: number;
  currency_mode: CURRENCY_MODE;
  call_money: number;
  change_amount: boolean;
  playing_card?: string;
}

export const PlayerCard = (props: PropsPlayerCard) => {
  const leftCard = useRef<HTMLImageElement>(null);
  const rightCard = useRef<HTMLImageElement>(null);
  const badge = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [currentChips, setCurrentChips] = useState<number>(0);
  useEffect(() => {
    setCurrentChips(props.mp);
  }, [props.mp]);
  useEffect(() => {
    if (props.change_amount) {
      if (props.question_history.length === 0) {
        setCurrentChips(props.mp - props.amount);
        return;
      }
      let amountHSum = 0;
      for (let i = 0; i < props.question_history.length; i++) {
        if (props.question_history[i]['player'] === props.player) {
          amountHSum += props.question_history[i]['amount'];
        }
      }
      setCurrentChips(props.mp - amountHSum);
    } else {
      if (props.turn && props.amount) {
        setCurrentChips((_currentChips) => _currentChips - props.amount);
      }
    }
  }, [props.turn, props.amount]);
  useEffect(() => {
    if (leftCard.current != null && rightCard.current != null) {
      if (props.me) {
        (leftCard.current as HTMLImageElement).style.transform = 'rotate(-10deg)';
        (rightCard.current as HTMLImageElement).style.transform = 'rotate(10deg)';
        (container.current as HTMLDivElement).style.transform = 'translateY(10px)';
      } else if (props.dealer === props.player) {
        (container.current as HTMLDivElement).style.transform = 'translateY(0px)';
        (rightCard.current as HTMLImageElement).style.transform = 'rotate(0deg)';
        (leftCard.current as HTMLImageElement).style.transform = 'rotate(0deg)';
        (container.current as HTMLDivElement).style.transform = 'translateY(10px)';
      } else {
        (leftCard.current as HTMLImageElement).style.transform = 'rotate(0deg)';
        (rightCard.current as HTMLImageElement).style.transform = 'rotate(0deg)';
        (container.current as HTMLDivElement).style.transform = 'translateY(10px)';
      }
    }
  }, [props.me, props.dealer, props.player, leftCard, rightCard]);
  const renderChips = (quantity: number) => {
    let array = [];
    for (let i: number = 0; i < quantity; i++) {
      if (i < 3) {
        array.push(
          <img
            key={i}
            src={chip}
            width={12}
            height={12}
            className="transform -translate-x-1/2"
            style={{
              zIndex: 99,
              position: "absolute",
              left: i === 0 ? 'calc(50% - 6px)' : i === 1 ? 'calc(50% + 6px)' : i === 2 ? 'calc(50%)' : 'calc(50%)',
              top: i === 0 ? 0 : i === 1 ? 0 : i === 2 ? 12 : 0,
            }}
            alt=""
          />
        );
      }
    }
    return (
      <div className="w-full">
        {array}
      </div>
    );
  };
  const renderLabel = (action: string) => {
    if (action === 'posts small blind' || action === 'posts the small blind') {
      return 'SB ';
    } else if (
      action === 'posts big blind' ||
      action === 'posts the big blind'
    ) {
      return 'BB ';
    } else if (action === 'posts ante' || action === 'posts the ante') {
      return 'ante ';
    }
    return action + ' ';
  };

  return (
    <div className={classNames(styles['player-wrapper'])} ref={container}>
      {props.chipPos === 'left' || props.chipPos === 'top' ? (
        <>
          <div className={classNames(
            "absolute",
            styles[`chipP${props.player}`]
          )}>
            <Rotate delay={300} triggerOnce>
              {renderChips(
                (renderLabel(props.action) === 'ante ' && props.turn) ||
                  renderLabel(props.action) === 'SB '
                  ? 1
                  : renderLabel(props.action) === 'calls ' ||
                    renderLabel(props.action) === 'BB '
                    ? 2
                    : renderLabel(props.action) === 'raise to ' ||
                      renderLabel(props.action) === 'bets ' ||
                      renderLabel(props.action) === 'all-in '
                      ? 3
                      : 0
              )}
            </Rotate>
          </div>

          <div className={classNames(
            "absolute",
            "w-full flex justify-center",
            styles[`gameChipBBWrapper${props.player}`],
          )}>
            {(renderLabel(props.action) === 'ante ' && props.turn) ||
              (renderLabel(props.action) !== 'ante ' && props.action !== '?') ? (
              <span className="text-white text-[10px] lg:text-xs">
                {renderLabel(props.action) === 'fold ' ? '' : renderLabel(props.action)}
                <span className="font-black">
                  {props.action === 'calls '
                    ? `${numberWithCommas(
                      props.currency_mode === "dollar" ? props.display_amount : Number((props.display_amount / props.bb).toFixed(2))
                    )}`
                    : props.amount
                      ? `${numberWithCommas(
                        props.currency_mode === "dollar" ? props.display_amount : Number((props.display_amount / props.bb).toFixed(2))
                      )}`
                      : ''
                  }
                </span>
                <span className="font-bold">{props.currency_mode === "bb" && (props.amount ? <span className="text-[10px] lg:text-xs text-primary-golden font-medium">{' BB'}</span> : '')}</span>
              </span>
            ) : props.action === '?' ? (
              <img src={question_chip} className="w-4 h-4" alt="" />
            ) : null}
          </div>
        </>
      ) : null}

      <div className={classNames("flex justify-center items-center gap-0.5")}>
        {renderLabel(props.action) === 'ante ' && props.pot < props.amount * props.count_of_players ? (
          <div style={{ height: 56 }} />
        ) : props.cards.length > 0 ? (
          props.cards.map((card, _index) => (
            <Rotate delay={300} triggerOnce key={_index}>
              <div className="flex justify-center items-center">
                {0 === _index ? (
                  <img
                    ref={leftCard}
                    className={'cardImage w-8 h-12 md:w-10 md:h-14'}
                    src={renderCard(card, props.playing_card)}
                    alt=""
                    draggable="false"
                  />
                ) : (
                  <img
                    ref={rightCard}
                    className={'cardImage w-8 h-12 md:w-10 md:h-14'}
                    src={renderCard(card, props.playing_card)}
                    alt=""
                    draggable="false"
                  />
                )}
              </div>
            </Rotate>
          ))
        ) : (
          <Roll triggerOnce>
            <img
              style={
                (props.action === 'folds' || props.action === 'fold')
                  ? { opacity: 0.3 }
                  : {}
              }
              ref={leftCard}
              className={'cardImage w-8 h-12 md:w-10 md:h-14'}
              src={cardBack}
              alt=''
              draggable='false'
            />
            <img
              style={
                (props.action === 'folds' || props.action === 'fold')
                  ? { opacity: 0.3 }
                  : {}
              }
              ref={rightCard}
              className={'cardImage w-8 h-12 md:w-10 md:h-14'}
              src={cardBack}
              alt=''
              draggable='false'
            />
          </Roll>
        )}
      </div>
      <div
        className="drop-shadow-glow-1 w-16 md:w-24"
        style={
          props.turn
            ? {
              // width: 94,
              borderRadius: 10,
              borderColor: 'var(--primary)',
              overflowX: 'hidden',
              WebkitFilter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
              filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))'
            }
            : {
              // width: 94
            }
        }
      >
        <div
          className={classNames(
            {
              [styles["pokerPlayerMPWrapperInverted"]]: props.dealer === props.player,
              [styles["pokerPlayerMPWrapper"]]: props.dealer !== props.player,
            },
            "flex justify-center items-center",
            "overflow-hidden",
            "rounded-lg",
            "badge",
            "mt-1",
          )}
          ref={badge}
          style={
            (props.action === 'folds' || props.action === 'fold') ? { opacity: 0.3 } : {}
          }
        >
          {/* <div
              style={
                props.mp > 999999 ? { transform: 'scale(.9)' } : { marginRight: 9 }
              }
            > */}
          <span
            className={classNames(
              props.dealer === props.player ? 'text-black' : 'text-white',
              'text-[10px] lg:text-xs',
              'py-1',
              // props.mp > 999999 ? 'transform scale-75' : ''
            )}
          >
            {props.position_label} {props.currency_mode === 'dollar' ? `${numberWithCommas(currentChips)}` : `${numberWithCommas(Math.round(currentChips / props.bb))} BB`}
          </span>
        </div>
      </div>

      {props.chipPos === 'right' || props.chipPos === 'bottom' ? (
        <>
          <div className={classNames(
            "absolute",
            styles[`chipP${props.player}`]
          )}>
            <Rotate delay={300} triggerOnce>
              {renderChips(
                (renderLabel(props.action) === 'ante ' && props.turn) ||
                  renderLabel(props.action) === 'SB '
                  ? 1
                  : renderLabel(props.action) === 'calls ' ||
                    renderLabel(props.action) === 'BB '
                    ? 2
                    : renderLabel(props.action) === 'raise to ' ||
                      renderLabel(props.action) === 'bets ' ||
                      renderLabel(props.action) === 'all-in '
                      ? 3
                      : 0
              )}
            </Rotate>
          </div>
          <div className={classNames(
            "absolute",
            "w-full flex justify-center",
            styles[`gameChipBBWrapper${props.player}`],
          )}>
            {(renderLabel(props.action) === 'ante ' && props.turn) ||
              (renderLabel(props.action) !== 'ante ' && props.action !== '?') ? (
              <span className="text-white text-[10px] lg:text-xs">
                {renderLabel(props.action) === 'fold ' ? '' : renderLabel(props.action)}
                <span className="font-black">
                  {
                    props.action === 'calls' ? (
                      `${numberWithCommas(
                        props.currency_mode === "dollar" ? props.display_amount : Number((props.display_amount / props.bb).toFixed(2))
                      )}`
                    ) : (
                      props.amount ? (
                        `${numberWithCommas(
                          props.currency_mode === 'dollar' ? props.display_amount : Number((props.display_amount / props.bb).toFixed(2))
                        )}`
                      ) : ''
                    )
                  }
                </span>
                <span className="font-bold">{props.currency_mode === "bb" && (props.amount ? <span className="text-[10px] lg:text-xs text-primary-golden font-medium">{' BB'}</span> : '')}</span>
              </span>
            ) : props.action === '?' ? (
              <img src={question_chip} className="w-4 h-4" alt="" />
            ) : null}
          </div>
        </>
      ) : null}

      {(props.dealer === props.player) && (
        props.player < 4 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute left-2 top-[120%] z-50"
            alt=""
          />
        ) : props.player < 5 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute -left-4 top-[100%] z-50"
            alt=""
          />
        ) : props.player < 6 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute -left-4 top-[30%] z-50"
            alt=""
          />
        ) : props.player < 9 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute left-4 -top-[40%] z-50"
            alt=""
          />
        ) : props.player < 10 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute -right-6 top-[40%] z-50"
            alt=""
          />
        ) : props.player < 11 ? (
          <img
            src={dealer_chip}
            width={16}
            height={16}
            className="absolute -right-6 top-[70%] z-50"
            alt=""
          />
        ) : null
      )}
    </div>
  )
}