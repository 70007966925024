export type IChipPos = "left" | "top" | "right" | "bottom";

export function handleChipPos(player: number): "left" | "top" | "right" | "bottom" {
    if (player === 1 || player === 2 || player === 3) {
        return 'bottom';
    } else if (player === 4 || player === 5) {
        return 'left';
    } else if (player === 6 || player === 7 || player === 8) {
        return 'top';
    } else {
        return 'right';
    }
}