import styles from './ic-vertical-separator.module.scss';

/* eslint-disable-next-line */
export interface IcVerticalSeparatorProps {}

export function IcVerticalSeparator(props: IcVerticalSeparatorProps) {
  return (
    <svg
      className={styles['container']}
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="32"
      fill="none"
      viewBox="0 0 3 32"
    >
      <path
        stroke="url(#paint0_linear_31_789)"
        strokeWidth="2"
        d="M1.5 31.55L1.5 0.55"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_31_789"
          x1="3.5"
          x2="1.361"
          y1="31.55"
          y2="0.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8BA73" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#E8BA73" stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IcVerticalSeparator;
