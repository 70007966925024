import styles from './ic-graph.module.scss';

/* eslint-disable-next-line */
export interface IcGraphProps {
  size: number;
}

export function IcGraph(props: IcGraphProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 19} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 5.5L12.4662 9.19371L10.2105 7.099L6 11.5" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 2V16H16.5" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcGraph.defaultProps = {
  size: 19,
}

export default IcGraph;
