import styles from './ic-close.module.scss';

/* eslint-disable-next-line */
export interface IcCloseProps {
  size: number;
}

export function IcClose(props: IcCloseProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.63586 14.3643L14.3638 1.63633" stroke="#15171A" strokeWidth="2" strokeLinecap="round"/>
      <path d="M14.3638 14.3643L1.63591 1.63634" stroke="#15171A" strokeWidth="2" strokeLinecap="round"/>
      <path d="M14.3638 14.3643L1.63591 1.63634" stroke="#15171A" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
}

IcClose.defaultProps = {
  size: 13,
}

export default IcClose;
