import classNames from 'classnames';
import styles from './input-icon.module.scss';
import { ChangeEvent } from 'react';

export interface InputIconProps {
  type: 'text' | 'password' | 'email';
  placeholder: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  prefix?: JSX.Element;
  surfix?: JSX.Element;
  className?: string;
}

export function InputIcon(props: InputIconProps) {
  return (
    <div className={classNames(
      styles['container'],
      'bg-color-3',
      'rounded-xl',
      'flex items-center gap-3',
      'px-5 py-4',
      props.className,
    )}>
      {props.prefix}
      <input
        className='shrink grow placeholder:text-color-5 text-white text-sm font-normal bg-transparent ring-0 border-none outline-none leading-[21px] placeholder:font-normal'
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      {props.surfix}
    </div>
  );
}

export default InputIcon;
