// import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider as BusProvider } from 'react-bus'
import App from './app/app';

console.log("NODE ENV v2024-09-15 ", process.env.NODE_ENV)

const googleClientId = process.env.NX_GOOGLE_CLIENT_ID ?? '';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <StrictMode>
  <GoogleOAuthProvider clientId={googleClientId}>
    <BrowserRouter>
      <BusProvider>
        <App />
      </BusProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
  // </StrictMode>
);
