import { useState } from 'react';
import styles from './accordion.module.scss';
import classNames from 'classnames';

export interface FAQ {
  question: string;
  answer: string;
}

/* eslint-disable-next-line */
export interface AccordionProps {
  data: FAQ[]
}

export function Accordion(props: AccordionProps) {
  const [opened_index, set_opened_index] = useState<number>(0);
  const [once_opened_indexs, set_once_opened_indexs] = useState<number[]>([]);
  const collapse_handler = (index: number) => {
    set_opened_index((_prev) => _prev === index ? -1 : index);
    set_once_opened_indexs((_prev) => _prev.includes(index) ? _prev : [..._prev, index]);
  }
  return (
    <div className={classNames(
      styles['container'],
      'flex flex-col gap-4'
    )}>
      {props.data.map((item, index) => (
        <div className='rounded-lg bg-[#312D26] p-4' key={index}>
          <div className={classNames(
            'flex justify-between',
            {
              'mb-4': opened_index === index
            },
            'cursor-pointer'
          )} onClick={() => collapse_handler(index)}>
            <p className={classNames(
              {
                'text-primary-golden': once_opened_indexs.includes(index),
                'text-white': !once_opened_indexs.includes(index),
              }
            )}>{item.question}</p>
            <button>
              <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: opened_index === index ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                <path d="M2 6L7 1L12 6" stroke={opened_index === index ? "#D5B763" : "#ffffff"} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div className='overflow-hidden transition-all text-white' style={{ height: opened_index === index ? 'auto' : '0px' }} dangerouslySetInnerHTML={{ __html: item.answer }} />
        </div>
      ))}
    </div>
  );
}

export default Accordion;
