import classNames from 'classnames';
import LeaderboardItem, { LeaderboardItemProps } from '../leaderboard-item/leaderboard-item';
import styles from './leaderboard-group.module.scss';
import _ from 'lodash';
import { IcThreeDots } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface LeaderboardGroupProps {
  leaders: Array<LeaderboardItemProps>; // sorted array
  self?: LeaderboardItemProps;
  refresh_handler?: () => void;
}

export function LeaderboardGroup(props: LeaderboardGroupProps) {
  const refresh_handler = _.debounce(() => props.refresh_handler?.(), 200);

  return (
    <div className={classNames(
      styles['container'],
      'w-full overflow-auto',
      'flex flex-col gap-1',
      'relative',
      'group'
    )}>
      <div className='hidden group-hover:hidden absolute left-1/2 top-1/2 transform -translate-x-1/2 -tranlsate-y-1/2 cursor-pointer' onClick={refresh_handler}>
        Refresh
      </div>

      <div
        className={classNames(
          'grow shrink flex flex-col gap-1 thin-scrollbar px-4',
          {
            'h-[332px] ': (props.leaders.length <= 6),
            '!h-[276px] overflow-auto': (props.leaders.length > 5),
          }
        )}
      >
        {props.leaders.length > 0 ? props.leaders.map((leader, id) => <LeaderboardItem key={id} {...leader} prev_rank={id > 0 ? props.leaders[id - 1].rank : 0} />) : (
          <div className='w-full h-full flex justify-center items-center'>
            <p className='text-white'>No Leaderboard Users</p>
          </div>
        )}
      </div>

      {(props.leaders.length > 5) && (
        <div className='h-[28px] flex my-1 justify-center items-center'>
          <IcThreeDots size={18} />
        </div>
      )}

      <div className='grow shrink flex flex-col gap-1 px-4'>
        {props.self && <LeaderboardItem {...props.self} you={true} prev_rank={0} />}
      </div>
    </div>
  );
}

export default LeaderboardGroup;
