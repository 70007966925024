import styles from './ic-identify.module.scss';

/* eslint-disable-next-line */
export interface IcIdentifyProps {
  size: number;
}

export function IcIdentify(props: IcIdentifyProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 25 / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 7C3.5 4.64298 3.5 3.46447 4.23223 2.73223C4.96447 2 6.14298 2 8.5 2H14.5C16.857 2 18.0355 2 18.7678 2.73223C19.5 3.46447 19.5 4.64298 19.5 7V17C19.5 19.357 19.5 20.5355 18.7678 21.2678C18.0355 22 16.857 22 14.5 22H8.5C6.14298 22 4.96447 22 4.23223 21.2678C3.5 20.5355 3.5 19.357 3.5 17V7ZM16.5 15C16.5 16.1046 15.6046 17 14.5 17C13.3954 17 12.5 16.1046 12.5 15C12.5 13.8954 13.3954 13 14.5 13C15.6046 13 16.5 13.8954 16.5 15ZM14.1033 17.3039C14.2331 17.3059 14.3655 17.308 14.5 17.308C14.6345 17.308 14.7668 17.3059 14.8966 17.3039C16.1826 17.2839 17.2118 17.2679 17.4486 19.2999C17.5125 19.8485 17.0523 20.3 16.5 20.3H12.5C11.9477 20.3 11.4875 19.8485 11.5514 19.2999C11.7882 17.2679 12.8173 17.2839 14.1033 17.3039ZM5.75 6C5.75 5.58579 6.08579 5.25 6.5 5.25H10.5C10.9142 5.25 11.25 5.58579 11.25 6C11.25 6.41421 10.9142 6.75 10.5 6.75H6.5C6.08579 6.75 5.75 6.41421 5.75 6ZM6.5 8.25C6.08579 8.25 5.75 8.58579 5.75 9C5.75 9.41421 6.08579 9.75 6.5 9.75H14.5C14.9142 9.75 15.25 9.41421 15.25 9C15.25 8.58579 14.9142 8.25 14.5 8.25H6.5Z" fill="#E8BA73"/>
    </svg>
    
  );
}

IcIdentify.defaultProps = {
  size: 24,
}

export default IcIdentify;
