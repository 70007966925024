import { ReactNode, useEffect, useState } from 'react';
import styles from './card-category.module.scss';
import classNames from 'classnames';
import Card from '../card/card';
import Ribbon from '../ribbon/ribbon';
import BadgeComingSoon from '../badge-coming-soon/badge-coming-soon';

/* eslint-disable-next-line */
export interface CardCategoryProps {
  label?: ReactNode;
  bannerImages: Array<string>;
  icon: ReactNode;
  title: string;
  description?: string;
  upcoming?: boolean;
  onClickHandler: () => void;
}

export function CardCategory(props: CardCategoryProps) {
  const [bannerIndex, setBannerIndex] = useState<number>(0);
  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setBannerIndex((_prev) => props.bannerImages.length - 1 === _prev ? 0 : _prev + 1)
    }, 3000);
    return () => clearInterval(intervalTimer);
  }, [props.bannerImages]);
  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      'rounded-2xl',
      'overflow-hidden',
      'relative',
      'group',
      'cursor-pointer'
    )}>
      {props.upcoming && (
        // <Ribbon content={<span className="uppercase text-xs font-bold text-black">Coming Soon</span>} />
        <BadgeComingSoon />
      )}
      <div className='relative w-full h-[226px] flex justify-center items-center'>
        {props.label && (
          <div className='absolute left-3 md:left-5 xl:left-7 top-3 h-6 flex items-center px-3 rounded-full text-primary-golden font-light text-xs bg-secondary-transparent-coal'>
            {props.label}
          </div>
        )}
        {props.bannerImages.length > 0 ? <img className='min-w-full min-h-full object-cover' src={props.bannerImages[bannerIndex]} /> : <div className='w-full h-full flex justify-center items-center bg-secondary-transparent-coal'>No Image</div>}
      </div>
      <Card className='relative w-full flex flex-col -mt-5 gap-2 cursor-pointer transition-all duration-200 group-hover:border-primary-golden backdrop-blur-lg' size='small' onClick={props.onClickHandler}>
        {props.icon}
        <h2 className='h-16 text-2xl text-white font-medium leading-[28px] tracking-[-2px] font-inter line-clamp-2'>{props.title}</h2>
        {props.description && <p className='h-5 text-sm text-secondary-grey font-normal leading-[20px] font-inter line-clamp-2'>{props.description}</p>}
      </Card>
    </div>
  );
}

export default CardCategory;
