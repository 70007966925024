import { useEffect, useState } from "react";
import { IQuestion } from "../interfaces/question.interface";
import lodash from "lodash";
import classNames from "classnames";
import { attachTooltip } from "../utilities/attach-tooltip";
import { IcCheck, IcClose, IcClose1, IcClose2 } from "@clc-v2/icons";
import "./tooltip.scss";
import "./answer-panel.scss";
import { embedVideo } from "@clc-v2/utilities";
import mastered from "./mastered.png";
import { useListener } from 'react-bus';

export interface PropsAnswerPanel {
	frozen: boolean;
	index: number;
	question: IQuestion;
	dictionary: Record<string, string>;
	mastered: boolean;
	show_reward?: boolean;
	select_answer: (correct: boolean) => void;
	next?: () => void;
}

const ANSWER_ID = ["A", "B", "C", "D", "E", "F"]

export const AnswerPanel = (props: PropsAnswerPanel) => {
	const [orientation, set_orientation] = useState<"portrait" | "landscape">("landscape");
	const [selected_answer, set_selected_answer] = useState<number>(-1);
	const [selected_answer_correct, set_selected_answer_correct] = useState<boolean>(false);
	const [show_reward, set_show_reward] = useState<boolean>(false);
	const [reward, set_reward] = useState<number>(0);
	const select_answer = lodash.debounce(props.select_answer, 200);
	const select_answer_handler = (correct: boolean, id: number) => {
		if (selected_answer < 0) {
			set_selected_answer_correct(correct);
			set_selected_answer(id);
			select_answer(correct);
			set_show_reward(true);
			setTimeout(() => set_show_reward(false), 5000);
		}
	}
	const show_reward_handler = (reward_point: any) => {
		if (typeof reward_point === 'number') {
			set_reward(reward_point);
		}
	}
	useListener('show-reward', show_reward_handler);
	useEffect(() => {
		set_selected_answer(-1);
		set_reward(0);
	}, [props.question]);
	useEffect(() => {
		const orientationChangeHandler = (e: MediaQueryListEvent) => {
			const portrait = e.matches;
			if (portrait) {
				set_orientation("portrait");
			} else {
				set_orientation("landscape");
			}
		}
		const portrait = window.matchMedia("(orientation: portrait)").matches;
		if (portrait) {
			set_orientation("portrait");
		} else {
			set_orientation("landscape");
		}
		window.matchMedia("(orientation: portrait)").addEventListener("change", orientationChangeHandler);
		return () => {
			window.matchMedia("(orientation: portrait)").removeEventListener("change", orientationChangeHandler);
		}
	}, []);
	return (
		<div className={classNames(
			'p-4 md:rounded-2xl bg-gradient-radial from-color-3 to-secondary-coal thin-scrollbar backdrop-blur rounded-md',
			orientation === 'portrait' ? 'h-fit' : 'h-[375px] lg:h-[528px] overflow-y-auto'
		)}>
			{(props.mastered) && (
				<img className="absolute top-0 right-0" src={mastered} alt='Mastered' />
			)}
			{
				props.question.hand_number > 0 ? (
					<>
						<div className="flex items-center gap-4">
							<span className={classNames(
								"font-medium text-text-golden",
								{
									"text-base md:text-2xl": orientation === 'portrait',
									"text-sm lg:text-2xl": orientation === 'landscape'
								}
							)}>Hand {props.question.hand_number}</span>
							{props.show_reward && show_reward && reward !== 0 && (
								<span className={classNames(
									"font-bol",
									{
										"text-green": reward >= 0,
										"text-red-500": reward < 0
									},
									// {
									// 	"text-sm": orientation === 'portrait',
									// 	"text-sm": orientation === 'landscape'
									// },
									"text-sm",
									"animate-pulse"
								)}>{reward >= 0 ? '+' : ''}{reward} XP</span>
							)}
						</div>
						{/* <p className="text-2xl font-medium text-white">Question #{props.index}</p> */}
						<div className={classNames(
							"font-light text-gray-400 question",
							{
								"text-sm md:text-base mt-2 md:mt-4 mb-2 md:mb-6": orientation === 'portrait',
								"text-xs lg:text-base mt-1 md:mt-2 lg:mt-4 mb-1 md:mb-2 lg:mb-6": orientation === 'landscape'
							}
						)} dangerouslySetInnerHTML={{ __html: props.question.description }} />
						<div className="flex flex-col gap-2 mt-4 relative">
							{props.frozen && <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20"></div>}
							{props.question.answers.map((answer, id) => (
								<button
									className={classNames(
										"w-full",
										"grow-0 shrink-0",
										"flex justify-start items-center",
										"rounded-md",
										{
											"h-8 md:h-12": orientation === 'portrait',
											"h-6 md:h-8 lg:h-12": orientation === 'landscape',
											"px-2 md:px-4": orientation === 'portrait',
											"text-sm md:text-base": orientation === 'portrait',
											"px-1 md:px-2 lg:px-4": orientation === 'landscape',
											"text-xs md:text-sm lg:text-base": orientation === 'landscape',
										},
										{
											"bg-white hover:bg-primary-golden text-black hover:text-white": selected_answer < 0 || (id !== selected_answer && !answer.correct),
											"bg-green text-black": selected_answer >= 0 && answer.correct,
											"bg-primary-golden text-white": id === selected_answer && !answer.correct,
										},
									)}
									key={id}
									onClick={() => select_answer_handler(answer.correct, id)}
								>
									{ANSWER_ID[id]}. {answer.text}
								</button>
							))}
						</div>
						{
							selected_answer >= 0 && (
								<>
									<hr className="mt-6" />
									<div className="mt-4 mb-2 flex items-center gap-2">
										{selected_answer_correct ? <IcCheck /> : <IcClose2 size={24} />}
										<span className={classNames(
											"text-white font-semibold",
											{
												"text-base": orientation === 'portrait',
												"text-sm lg:text-base": orientation === 'landscape'
											}
										)}>Explanation</span>
									</div>

									{(props.question.video.link) && (
										<div className="mt-2 relative">
											<iframe title={props.question.video.link} width="100%" height="100%" style={{ backgroundColor: '#000' }} src={embedVideo(props.question.video.link)} allowFullScreen></iframe>
										</div>
									)}
									<div className={classNames(
										"mt-2 text-white text-sm relative",
										{
											"text-sm": orientation === 'portrait',
											"text-xs lg:text-sm": orientation === 'landscape'
										}
									)} dangerouslySetInnerHTML={{ __html: attachTooltip(props.question.answers[selected_answer]?.explanation ?? '', props.dictionary) }} />
									{props.next && <div className="mt-4">
										<button className={classNames(
											"rounded-md w-full flex justify-center items-center bg-primary-golden text-black hover:text-white",
											{
												"h-8 text-base": orientation === 'portrait',
												"h-6 text-sm lg:h-8 lg:text-base": orientation === 'landscape'
											}
										)} onClick={() => {
											props.next?.();
											set_show_reward(false);
											set_reward(0);
										}}>Next Question</button>
									</div>}
								</>
							)
						}
					</>
				) : (
					<></>
				)
			}
		</div >
	)
}