import { IcDoubleLeft, IcDoubleRight, IcPause, IcPlay1, IcRefresh } from "@clc-v2/icons"
import lodash from "lodash"
import { useEffect, useMemo, useState } from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from "classnames";

export type PLAYING_STATUS = "pause" | "play"
export type CURRENCY_MODE = "dollar" | "bb"

export interface PropsController {
    init_block_play_btn: boolean;
    finished: boolean;
    playing_status: PLAYING_STATUS;
    playing_speed: number;
    currency_mode: CURRENCY_MODE;
    prev_handler: () => void;
    play_handler: () => void;
    next_handler: () => void;
    refresh_handler: () => void;
    speed_change_handler: (_new_playing_speed: number) => void;
    currency_mode_change_handler: () => void;
}

export const Controller = (props: PropsController) => {
    const [speed, set_speed] = useState<number>(5);
    const prev_handler = lodash.debounce(props.prev_handler, 100);
    const play_handler = lodash.debounce(props.play_handler, 200);
    const next_handler = lodash.debounce(props.next_handler, 100);
    const refresh_handler = lodash.debounce(props.refresh_handler, 200);
    const speed_change_handler = useMemo(() => lodash.debounce(props.speed_change_handler, 200), []);
    const currency_mode_change_handler = lodash.debounce(props.currency_mode_change_handler, 200);
    useEffect(() => {
        set_speed(props.playing_speed);
    }, []);
    useEffect(() => {
        speed_change_handler(speed);
    }, [speed]);
    return (
        <div className="w-full h-full flex justify-between items-center gap-2 bg-card-glow-fill rounded-full p-2 drop-shadow-glow">
            <div className="w-8 h-8 lg:w-12 lg:h-12 flex justify-center items-center">
                <button className="w-8 h-8 flex justify-center items-center hover:bg-primary-golden" onClick={prev_handler}><IcDoubleLeft /></button>
            </div>
            <div className="w-10 h-10 lg:w-16 lg:h-16 flex justify-center items-center bg-card-glow-fill rounded-full">
                <button
                    className={classNames(
                        "w-8 h-8 lg:w-12 lg:h-12 flex justify-center items-center rounded-full",
                        {
                            "bg-primary-golden": !props.init_block_play_btn && !props.finished,
                            "bg-gray-500": props.init_block_play_btn || props.finished,
                        }
                    )}
                    onClick={play_handler}
                    disabled={props.init_block_play_btn || props.finished}
                >
                    {props.playing_status === "play" ? (
                        <IcPause />
                    ) : (
                        <IcPlay1 />
                    )}
                </button>
            </div>
            <div className="w-8 h-8 lg:w-12 lg:h-12 flex justify-center items-center">
                <button className="w-6 h-6 lg:w-8 lg:h-8 flex justify-center items-center hover:bg-primary-golden" onClick={next_handler}><IcDoubleRight /></button>
            </div>
            <button className="w-8 h-8 lg:w-10 lg:h-10 rounded-full flex justify-center items-center bg-primary-golden text-white font-bold" onClick={refresh_handler}>
                <IcRefresh />
            </button>
            <div className="w-8 h-8 lg:w-12 lg:h-12 flex justify-center items-center">
                <span className="text-white font-light text-xs lg:text-sm">1X</span>
            </div>
            <div className="w-16 lg:w-20">
                <Slider
                    min={1}
                    max={10}
                    defaultValue={speed}
                    onChange={(_val) => set_speed(_val as number)}
                />
            </div>
            <div className="w-8 lg:w-12 flex justify-center items-center">
                <span className="text-white font-light text-xs lg:text-sm">10X</span>
            </div>
            <button className="w-8 h-8 lg:w-10 lg:h-10 rounded-full flex justify-center items-center bg-primary-golden text-white font-bold text-xs lg:text-base" onClick={currency_mode_change_handler}>
                {props.currency_mode === "dollar" ? (<span>$</span>) : (<span>BB</span>)}
            </button>
        </div>
    )
}