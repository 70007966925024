import styles from './ic-menu.module.scss';

/* eslint-disable-next-line */
export interface IcMenuProps {
  size: number;
}

export function IcMenu(props: IcMenuProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 16 / 20} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.45456H19" stroke="#E8BA73" strokeWidth="2" strokeLinecap="round"/>
      <path d="M1 8.00003H19" stroke="#E8BA73" strokeWidth="2" strokeLinecap="round"/>
      <path d="M1 14.5455H19" stroke="#E8BA73" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
}

IcMenu.defaultProps = {
  size: 20,
}

export default IcMenu;
