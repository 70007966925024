export const ROLES: { [key: string]: number } = {
  USER: 0b1,
  MANAGE_PAGE_VIEW: Math.pow(2, 3),
  MANAGE_PAGE_TLQ_VIEW: Math.pow(2, 4) + Math.pow(2, 3),
  MANAGE_PAGE_TLQ_UPDATE: Math.pow(2, 5) + Math.pow(2, 4) + Math.pow(2, 3), // CLC Content Manage Role
  MANAGE_PAGE_LIBRARY_VIEW: Math.pow(2, 6) + Math.pow(2, 3),
  MANAGE_PAGE_LIBRARY_UPDATE: Math.pow(2, 7) + Math.pow(2, 6) + Math.pow(2, 3), // CLC Content Manage Role
  MANAGE_PAGE_CLC_DATA_VIEW: Math.pow(2, 8) + Math.pow(2, 3),
  MANAGE_PAGE_CLC_DATA_UPDATE: Math.pow(2, 9) + Math.pow(2, 8) + Math.pow(2, 3),
  MANAGE_PAGE_USERS_VIEW: Math.pow(2, 10) + Math.pow(2, 3),
  MANAGE_PAGE_USERS_UPDATE: Math.pow(2, 11) + Math.pow(2, 10) + Math.pow(2, 3),
  MANAGE_PAGE_CB_PAYMENT_VIEW: Math.pow(2, 12) + Math.pow(2, 3),
  MANAGE_PAGE_CB_PAYMENT_UPDATE: Math.pow(2, 13) + Math.pow(2, 12) + Math.pow(2, 3),
}

export const ADMIN_ROLE = Object.keys(ROLES).reduce((previous, current, id) => previous | ROLES[current], 0)
export const CLC_Content_Manage_ROLE =
  ROLES['MANAGE_PAGE_TLQ_VIEW'] |
  ROLES['MANAGE_PAGE_TLQ_UPDATE'] |
  ROLES['MANAGE_PAGE_LIBRARY_VIEW'] |
  ROLES['MANAGE_PAGE_LIBRARY_UPDATE'] |
  ROLES['MANAGE_PAGE_CLC_DATA_VIEW'] |
  ROLES['MANAGE_PAGE_CLC_DATA_UPDATE']
export const CB_Payment_Manage_ROLE =
  ROLES['MANAGE_PAGE_CB_PAYMENT_VIEW'] |
  ROLES['MANAGE_PAGE_CB_PAYMENT_UPDATE']
export const USERS_Manage_ROLE =
  ROLES['MANAGE_PAGE_USERS_VIEW'] |
  ROLES['MANAGE_PAGE_USERS_UPDATE']

// ADMIN ROLES 524,287 = 0b111 1111 1111 1111 1111
// CLC Content Manage ROLES 32513 = 0b0 0111 1111 0000 0001
// Library & CLC Data 16,129 = 0b11_1111_0000_0001

export function checkRole(requiredRole: number, userRole: number): boolean {
  return (requiredRole & userRole) === requiredRole;
}
