import styles from './ic-right.module.scss';

/* eslint-disable-next-line */
export interface IcRightProps {
  size: number;
  color: string;
}

export function IcRight(props: IcRightProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 8 / 5} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.799988 0.799999L3.99999 4L0.799988 7.2" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcRight.defaultProps = {
  size: 5,
  color: 'white'
}

export default IcRight;
