import styles from './ic-tool.module.scss';

/* eslint-disable-next-line */
export interface IcToolProps {
  size: number;
}

export function IcTool(props: IcToolProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2718 13.4571L12.1491 15.3344C12.3565 15.5461 12.604 15.7142 12.8772 15.829C13.1504 15.9438 13.4437 16.0029 13.74 16.0029C14.0363 16.0029 14.3297 15.9438 14.6029 15.829C14.876 15.7142 15.1236 15.5461 15.3309 15.3344V15.3344C15.5426 15.127 15.7107 14.8795 15.8255 14.6063C15.9403 14.3331 15.9995 14.0398 15.9995 13.7435C15.9995 13.4472 15.9403 13.1538 15.8255 12.8806C15.7107 12.6075 15.5426 12.3599 15.3309 12.1525L14.09 10.9116" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.67856 7.87293L5.49899 5.69336" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.16267 6.13887L5.49905 5.69342L5.94451 4.35703L4.16267 2.5752L2.38083 4.35703L4.16267 6.13887Z" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.794 4.49751L14.4446 5.88907L12.1569 3.61341L13.5075 2.22121C13.0554 2.02518 12.558 1.95758 12.0699 2.02584C11.1644 2.10362 10.3225 2.52355 9.71567 3.20015C9.10883 3.87674 8.78261 4.75916 8.80343 5.6678C8.79863 6.02499 8.86505 6.37956 8.9988 6.71081L2.72736 12.2364C2.50988 12.4303 2.33356 12.666 2.20887 12.9293C2.08419 13.1927 2.01367 13.4784 2.0015 13.7695C1.98933 14.0606 2.03577 14.3512 2.13804 14.6241C2.24032 14.8969 2.39636 15.1464 2.5969 15.3578C2.80207 15.5724 3.05057 15.7409 3.32588 15.852C3.60119 15.9631 3.89701 16.0144 4.19366 16.0024C4.49031 15.9903 4.781 15.9153 5.0464 15.7822C5.3118 15.6491 5.54583 15.4611 5.73294 15.2305L11.2859 8.98775C11.8794 9.16182 12.5036 9.20524 13.1155 9.11502C13.7603 8.98182 14.3565 8.67521 14.8399 8.22823C15.3233 7.78125 15.6756 7.21083 15.8589 6.57844C16.0672 5.8966 16.0443 5.16505 15.794 4.49751Z" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcTool.defaultProps = {
  size: 18,
}

export default IcTool;
