import styles from './ic-logout.module.scss';

/* eslint-disable-next-line */
export interface IcLogoutProps {
  size: number;
}

export function IcLogout(props: IcLogoutProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.75812 11.8836C6.76742 13.6138 8.64184 14.7673 10.7325 14.7673C13.9046 14.7673 16.5 12.172 16.5 8.99986C16.5 5.82777 13.9046 3.23242 10.7325 3.23242C8.56975 3.23242 6.76742 4.38591 5.75812 6.11614" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.4535 9H1.36047" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.16279 10.8021L1 8.63935L3.16279 6.47656" stroke="#E8BA73" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcLogout.defaultProps = {
  size: 18,
}

export default IcLogout;
