import { LibraryAuthor } from "@prisma/client";
import { ILibraryVideoWithTagWithAuthor } from "../interfaces/library.interface";

export const convertVideoListToAuthorGroup = (videos: ILibraryVideoWithTagWithAuthor[]) => {
    const result: Array<{
        library_author_1: LibraryAuthor;
        library_author_2: LibraryAuthor | null;
        library_author_3: LibraryAuthor | null;
        library_author_4: LibraryAuthor | null;
        library_author_5: LibraryAuthor | null;
        videos: ILibraryVideoWithTagWithAuthor[];
    }> = []
    for (const video of videos) {
        const existing_index = result.map((a) => a.library_author_1.id + (a.library_author_2?.id ?? '') + (a.library_author_3?.id ?? '') + (a.library_author_4?.id ?? '') + (a.library_author_5?.id ?? '')).indexOf(video.library.library_author_1.id + (video.library.library_author_2?.id ?? ''))
        if (existing_index >= 0) {
            result[existing_index].videos = [...result[existing_index].videos, video]
        } else {
            result.push({
                library_author_1: video.library.library_author_1,
                library_author_2: video.library.library_author_2,
                library_author_3: video.library.library_author_3,
                library_author_4: video.library.library_author_4,
                library_author_5: video.library.library_author_5,
                videos: [video]
            })
        }
    }
    return result
}