import styles from './ic-password.module.scss';

/* eslint-disable-next-line */
export interface IcPasswordProps {
  size: number;
}

export function IcPassword(props: IcPasswordProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_926_1359)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 2.75C10.4289 2.75 8.75 4.42893 8.75 6.5V9.5H8.5C6.84315 9.5 5.5 10.8432 5.5 12.5V18.5C5.5 20.1569 6.84315 21.5 8.5 21.5H16.5C18.1569 21.5 19.5 20.1569 19.5 18.5V12.5C19.5 10.8432 18.1569 9.5 16.5 9.5H16.25V6.5C16.25 4.42893 14.5711 2.75 12.5 2.75ZM14.75 9.5V6.5C14.75 5.25736 13.7426 4.25 12.5 4.25C11.2574 4.25 10.25 5.25736 10.25 6.5V9.5H14.75ZM14.5 15.5C14.5 16.6046 13.6046 17.5 12.5 17.5C11.3954 17.5 10.5 16.6046 10.5 15.5C10.5 14.3954 11.3954 13.5 12.5 13.5C13.6046 13.5 14.5 14.3954 14.5 15.5Z" fill="#E8BA73"/>
      </g>
      <defs>
      <clipPath id="clip0_926_1359">
      <rect width="14" height="19" fill="white" transform="translate(5.5 2.5)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

IcPassword.defaultProps = {
  size: 24,
}

export default IcPassword;
