export function splitFileName(file_name: string) {
  // Find the last occurrence of '.' in the file name
  const lastDotIndex = file_name.lastIndexOf('.');

  // If '.' exists and it's not the first or last character
  if (lastDotIndex !== -1 && lastDotIndex !== 0 && lastDotIndex !== file_name.length - 1) {
    // Extract the name and extension
    const name = file_name.slice(0, lastDotIndex);
    const extension = file_name.slice(lastDotIndex + 1);
    return { name, extension };
  } else {
    // No extension found
    return { name: file_name, extension: '' };
  }
}