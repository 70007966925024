import styles from './ic-video.module.scss';

/* eslint-disable-next-line */
export interface IcVideoProps {
  size: number;
}

export function IcVideo(props: IcVideoProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 19} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="5.0332" width="8.8" height="7.93333" stroke="#E8BA73" strokeLinejoin="round"/>
      <path d="M16.5 5.5332L12.1667 8.99987L16.5 12.4665V5.5332Z" stroke="#E8BA73" strokeLinejoin="round"/>
    </svg>
  );
}

IcVideo.defaultProps = {
  size: 19,
}

export default IcVideo;
