import styles from './ic-money.module.scss';

/* eslint-disable-next-line */
export interface IcMoneyProps {
  size: number;
}

export function IcMoney(props: IcMoneyProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="9.42857" width="12.3529" height="8.57143" rx="1" fill="#E8BA73"/>
      <rect x="5.88235" y="10.2857" width="10.5882" height="6.85714" fill="#0F0F0F"/>
      <ellipse cx="6.32353" cy="10.7143" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.41177 12C9.41177 12.9468 8.62168 13.7143 7.64706 13.7143C8.62168 13.7143 9.41177 14.4818 9.41177 15.4286H12.9412C12.9412 14.4818 13.7313 13.7143 14.7059 13.7143C13.7313 13.7143 12.9412 12.9468 12.9412 12H9.41177Z" fill="#E8BA73"/>
      <ellipse cx="6.32353" cy="16.7143" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <ellipse cx="16.0294" cy="10.7143" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <ellipse cx="16.0294" cy="16.7143" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <g filter="url(#filter0_d_727_4578)">
      <rect x="6.96078" y="6" width="12.3529" height="8.57143" rx="1" fill="#E8BA73"/>
      <rect x="7.84314" y="6.85715" width="10.5882" height="6.85714" fill="#0F0F0F"/>
      <ellipse cx="8.28431" cy="7.28571" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3726 8.57143C11.3726 9.5182 10.5825 10.2857 9.60785 10.2857C10.5825 10.2857 11.3726 11.0532 11.3726 12H14.902C14.902 11.0532 15.6921 10.2857 16.6667 10.2857C15.6921 10.2857 14.902 9.5182 14.902 8.57143H11.3726Z" fill="#E8BA73"/>
      <ellipse cx="8.28431" cy="13.2857" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <ellipse cx="17.9902" cy="7.28571" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      <ellipse cx="17.9902" cy="13.2857" rx="1.32353" ry="1.28571" fill="#E8BA73"/>
      </g>
      <defs>
      <filter id="filter0_d_727_4578" x="4.76471" y="6" width="17.2353" height="13.5714" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_727_4578"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_727_4578" result="shape"/>
      </filter>
      </defs>
    </svg>
  );
}

IcMoney.defaultProps = {
  size: 24,
}

export default IcMoney;
