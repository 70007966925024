import styles from './ic-play.module.scss';

/* eslint-disable-next-line */
export interface IcPlayProps {
  size: number;
}

export function IcPlay(props: IcPlayProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 9L4 2L4 16L14 9Z" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcPlay.defaultProps = {
  size: 18,
}

export default IcPlay;
