export function attachTooltip(origin_html: string, dictionary: Record<string, string>) {
    let tmp = origin_html;
    tmp = tmp.replace(/<[^>]+>/g, "");
    const tmpArray = tmp.split(/\s+/).map((word) => {
        if (dictionary[word]) {
            return `<span class="tooltip text-primary-golden" data-text="${dictionary[word]}">${word}</span>`
        } else {
            return word
        }
    });

    // for (const keyword of Object.keys(dictionary).sort((a, b) => a.length < b.length ? 1 : -1)) {
    //     if (keyword) {
    //         if (keyword === '+') {
    //             tmp = tmp.replace('+', `<span class="tooltip text-primary-golden" data-text="${dictionary[keyword]}">${keyword}</span>`)
    //         } else {
    //             tmp = tmp.replace(new RegExp(`\\b${keyword}\\b`, "gi"), (match) => {
    //                 if (match.charAt(0) === match.charAt(0).toUpperCase()) {
    //                     return `<span class="tooltip text-primary-golden" data-text="${dictionary[keyword]}">${keyword.charAt(0).toUpperCase() + keyword.slice(1)}</span>`
    //                 } else {
    //                     return `<span class="tooltip text-primary-golden" data-text="${dictionary[keyword]}">${keyword}</span>`
    //                 }
    //             });
    //         }
    //     }
    // }
    return tmpArray.join(' ');
}