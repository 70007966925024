import classNames from 'classnames';
import Card from '../card/card';
import styles from './calendar-practices.module.scss';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { IcLeft, IcRight } from '@clc-v2/icons';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface CalendarPracticesProps {
	prevMonthHandler: () => Promise<boolean>;
	nextMonthHandler: () => Promise<boolean>;
	month: number;
	year: number;
	todayHands: {
		played: number;
	};
	played_history: { [day: number]: number[] };
}

export function CalendarPractices(props: CalendarPracticesProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [active_month, set_active_month] = useState<string>();
	const [initDate, setInitDate] = useState<number>(-1);
	const [endDay, setEndDay] = useState<number>(-1);
	const [today, setToday] = useState<{
		year: number;
		month: number;
		date: number;
	}>({ year: -1, month: -1, date: -1 });
	useEffect(() => {
		const _active_month = moment().year(props.year).month(props.month);
		set_active_month(_active_month.format('MMM YYYY'));
		setInitDate(_active_month.date(1).day());
		setEndDay(_active_month.add(1, 'months').subtract(1, 'day').date());
	}, [props.month, props.year]);
	useEffect(() => {
		setToday({
			year: moment().year(),
			month: moment().month(),
			date: moment().date()
		});
	}, []);
	const prevHandler = lodash.debounce(() => {
		setLoading(true);
		props.prevMonthHandler().then((value) => {
			setLoading(false);
		});
	}, 100);
	const nextHandler = lodash.debounce(() => {
		setLoading(true);
		props.nextMonthHandler().then((value) => {
			setLoading(false);
		});
	}, 100);
	return (
		<Card size='medium'>
			<div className={classNames(
				styles['container'],
				'flex items-center justify-center gap-12',
				'font-inter'
			)}>
				<div className='flex flex-col justify-center items-center gap-2'>
					<div className='flex items-center' title={`Today, answered ${props.todayHands.played} questions`}>
						<span className='text-text-golden font-inter font-medium text-5xl leading-[57px]'>{Object.keys(props.played_history).filter((key) => props.played_history[key as unknown as number].length > 0).length}</span>
						<span className='text-white font-inter font-medium text-5xl leading-[57px] mx-1'>/</span>
						<span className='text-white font-inter font-medium text-5xl leading-[57px]'>{moment().year(props.year).month(props.month).daysInMonth()}</span>
					</div>
					<span className='uppercase font-inter font-light text-xs leading-[14px] tracking-[2px] text-text-op70'>Days Practicing</span>
				</div>

				<div className='flex flex-col gap-2 w-36'>
					<div className='w-full flex justify-between items-center'>
						<button className='w-4 h-4 flex justify-center items-center bg-primary-golden rounded-full' disabled={loading} onClick={prevHandler}>
							<IcLeft />
						</button>
						<span className={`text-white text-sm font-medium leading-[14px] tracking-[2px] ${loading ? 'opacity-60' : ''} uppercase`}>{active_month}</span>
						<button className='w-4 h-4 flex justify-center items-center bg-primary-golden rounded-full' disabled={loading} onClick={nextHandler}>
							<IcRight />
						</button>
					</div>
					<div className={`grid grid-cols-7 gap-2 justify-center items-center ${loading ? 'opacity-60' : ''}`}>
						{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((date, id) => <span key={id} className='text-white font-light text-xs'>{date}</span>)}
						{new Array(initDate >= 0 ? initDate : 0).fill(true).map((_blank, id) => <div key={id} className='w-3 h-3 rounded-full bg-primary-dark-grey' />)}
						{new Array(endDay >= 0 ? endDay : 0).fill(true).map((_fill, _date) => (
							<div
								title={(props.year * 365 + props.month * 12 + _date + 1) <= (today.year * 365 + today.month * 12 + today.date) ? `Answered ${(props.played_history[_date + 1]?.length ?? 0)} questions` : ''}
								key={_date}
								className={classNames(
									'w-3 h-3',
									'rounded-full',
									{
										'bg-primary-golden': (props.played_history[_date + 1]?.length ?? 0) > 0,
										'bg-secondary-grey': (props.played_history[_date + 1]?.length ?? 0) <= 0,
									},
									{
										'border-primary-golden border-2 border-solid border-opacity-50': (today.date === (_date + 1) && today.month === props.month && today.year === props.year),
										'opacity-75': (props.year * 365 + props.month * 12 + _date + 1) > (today.year * 365 + today.month * 12 + today.date)
									}
								)}
							/>
						))}
						{new Array(7 - (initDate + endDay) % 7).fill(true).map((_fill, id) => <div key={id} className='w-3 h-3 rounded-full bg-primary-dark-grey' />)}
					</div>
				</div>

			</div>
		</Card>
	);
}

export default CalendarPractices;
