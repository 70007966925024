import { useEffect, useState } from "react";
import { Flip } from 'react-awesome-reveal';
import { renderCard } from "../utilities/render-card";

export interface IHouseOfCards {
	cards: string[];
	table_action: string;
	hand_index: number;
	count_of_players: number;
	playing_card?: string;
}

let flop_index = 0;
let turn_index = 0;
let river_index = 0;

export const HouseOfCards = (props: IHouseOfCards) => {
	// const [display, setDisplay] = useState<Array<{ card: string; show: boolean; }>>([
	// 	{ card: '', show: false },
	// 	{ card: '', show: false },
	// 	{ card: '', show: false },
	// 	{ card: '', show: false },
	// 	{ card: '', show: false },
	// ]);
	const [card1, set_card1] = useState<{ card: string; show: boolean }>({ card: '', show: false });
	const [card2, set_card2] = useState<{ card: string; show: boolean }>({ card: '', show: false });
	const [card3, set_card3] = useState<{ card: string; show: boolean }>({ card: '', show: false });
	const [card4, set_card4] = useState<{ card: string; show: boolean }>({ card: '', show: false });
	const [card5, set_card5] = useState<{ card: string; show: boolean }>({ card: '', show: false });
	useEffect(() => {
		if (props.hand_index === 0 || props.hand_index === props.count_of_players) {
			// const list: Array<{ card: string; show: boolean; }> = []
			if (props.cards.length >= 3) {
				// props.cards.forEach(card => {
				// 	list.push({ card, show: false });
				// });
				props.cards[0] && set_card1({ card: props.cards[0], show: false });
				props.cards[1] && set_card2({ card: props.cards[1], show: false });
				props.cards[2] && set_card3({ card: props.cards[2], show: false });
				props.cards[3] && set_card4({ card: props.cards[3], show: false });
				props.cards[4] && set_card5({ card: props.cards[4], show: false });
				if (props.cards.length === 3) {
					// list.push({ card: '', show: false }, { card: '', show: false });
					set_card4({ card: '', show: false });
					set_card5({ card: '', show: false });
				} else if (props.cards.length === 4) {
					// list.push({ card: '', show: false });
					set_card5({ card: '', show: false });
				}
			} else {
				for (let i = 0; i < 5; i++) {
					// list.push({ card: '', show: false });
					set_card1({ card: '', show: false });
					set_card2({ card: '', show: false });
					set_card3({ card: '', show: false });
					set_card4({ card: '', show: false });
					set_card5({ card: '', show: false });
				}
			}
			// setDisplay(list);
		}
	}, [props.cards, props.hand_index]);
	useEffect(() => {
		if (props.hand_index === 0) {
			flop_index = 0;
			turn_index = 0;
			river_index = 0;
		}
		// let _display = display;
		if (props.table_action === 'flop') {
			// _display[0].show = true;
			// _display[1].show = true;
			// _display[2].show = true;
			flop_index = props.hand_index;
			set_card1((_prev) => ({ ..._prev, show: true }));
			set_card2((_prev) => ({ ..._prev, show: true }));
			set_card3((_prev) => ({ ..._prev, show: true }));
		} else if (props.table_action === 'turn') {
			// _display[3].show = true;
			turn_index = props.hand_index;
			set_card4((_prev) => ({ ..._prev, show: true }));
		} else if (props.table_action === 'river') {
			// _display[4].show = true;
			river_index = props.hand_index;
			set_card5((_prev) => ({ ..._prev, show: true }));
		}
		if (flop_index !== 0 && flop_index > props.hand_index) {
			// _display[0].show = false;
			// _display[1].show = false;
			// _display[2].show = false;
			// _display[3].show = false;
			// _display[4].show = false;
			set_card1((_prev) => ({ ..._prev, show: false }));
			set_card2((_prev) => ({ ..._prev, show: false }));
			set_card3((_prev) => ({ ..._prev, show: false }));
			set_card4((_prev) => ({ ..._prev, show: false }));
			set_card5((_prev) => ({ ..._prev, show: false }));
		} else if (turn_index !== 0 && turn_index > props.hand_index) {
			// _display[3].show = false;
			set_card4((_prev) => ({ ..._prev, show: false }));
		} else if (river_index !== 0 && river_index > props.hand_index) {
			// _display[4].show = false;
			set_card5((_prev) => ({ ..._prev, show: false }));
		}
		// setDisplay(_display)
	}, [props.table_action, props.hand_index])
	return (
		<div className="flex justify-center items-center gap-2 z-50">
			{/* {
				display.map((item, index) => {
					return <div key={index} className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
						{item.show && (
							<Flip duration={1000}>
								<img src={renderCard(item.card)} className="w-10 h-14 md:w-14 md:h-20" />
							</Flip>
						)}
					</div>
				})
			} */}
			<div className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
				{card1.show && <Flip duration={1000}>
					<img src={renderCard(card1.card, props.playing_card)} className="w-10 h-14 md:w-14 md:h-20" />
				</Flip>}
			</div>
			<div className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
				{card2.show && <Flip duration={1000}>
					<img src={renderCard(card2.card, props.playing_card)} className="w-10 h-14 md:w-14 md:h-20" />
				</Flip>}
			</div>
			<div className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
				{card3.show && <Flip duration={1000}>
					<img src={renderCard(card3.card, props.playing_card)} className="w-10 h-14 md:w-14 md:h-20" />
				</Flip>}
			</div>
			<div className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
				{card4.show && <Flip duration={1000}>
					<img src={renderCard(card4.card, props.playing_card)} className="w-10 h-14 md:w-14 md:h-20" />
				</Flip>}
			</div>
			<div className="w-10 h-14 md:w-14 md:h-20 bg-white-light rounded-md">
				{card5.show && <Flip duration={1000}>
					<img src={renderCard(card5.card, props.playing_card)} className="w-10 h-14 md:w-14 md:h-20" />
				</Flip>}
			</div>
		</div>
	);
}