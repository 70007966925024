import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { API_BACKEND_ENDPOINT } from "../config";
import { defaultRedirectUrl } from "../components/LoginForm";

export const VerifyEmail = () => {
    const [url_search_params, set_url_search_params] = useSearchParams();
    const [loading, set_loading] = useState<boolean>(true);
    const [verified, set_verified] = useState<boolean>(false);
    useEffect(() => {
        if (url_search_params.get('email') && url_search_params.get('verify_token')) {
            console.log(url_search_params.get('email'), url_search_params.get('verify_token'))
            axios.post(
                `${API_BACKEND_ENDPOINT}/auth/verify-email`,
                {
                    email: url_search_params.get('email'),
                    token: url_search_params.get('verify_token')
                }
            ).then((res: { data: boolean }) => {
                if (res.data) {
                    set_verified(true);
                    setTimeout(() => {
                        window.location.href = defaultRedirectUrl
                    }, 2000);
                } else {
                    set_verified(false);
                }
            }).catch((error: AxiosError<{ message: string[] }>) => {
                if (error.response) {
                    console.error(error.response.data.message);
                } else {
                    console.error(error.message);
                }
                set_verified(false);
            }).finally(() => {
                set_loading(false);
            })
        }
    }, [url_search_params.get('email'), url_search_params.get('verify_token')]);
    return (
        <div className="w-screen h-screen flex justify-center items-center bg-black-dark">
            {loading ? (
                <div className="text-white">Verifying email address: {url_search_params.get('email')}</div>
            ) : verified ? (
                <div className="text-primary-golden">Verified Successfully</div>
            ) : (
                <div className="text-secondary-grey">Failed to verify</div>
            )}
        </div>
    )
}