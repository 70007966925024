import styles from './ic-clc.module.scss';

/* eslint-disable-next-line */
export interface IcClcProps {
  size: number;
}

export function IcClc(props: IcClcProps) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5307_7152)">
        <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#181A1B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.9937 14.634C19.5026 13.52 19.7864 12.2815 19.7864 10.9767C19.7864 9.67205 19.5027 8.43365 18.9938 7.31975L15.8501 8.9977C16.0984 9.60861 16.2352 10.2768 16.2352 10.9768C16.2352 11.677 16.0984 12.3451 15.85 12.9561L18.9937 14.634ZM18.1906 16.0345L15.1683 14.1526C14.3525 15.2275 13.1329 15.979 11.7354 16.1809L11.8541 19.7431C14.4732 19.4841 16.7551 18.0782 18.1906 16.0345ZM10.2358 19.7555L10.3544 16.1987C8.92534 16.0302 7.67176 15.2886 6.82971 14.2101L3.80491 16.0936C5.26782 18.1404 7.58515 19.5347 10.2358 19.7555ZM2.98706 14.6924C2.46111 13.5635 2.16742 12.3044 2.16742 10.9767C2.16742 9.64922 2.46107 8.39023 2.98696 7.26127L6.12839 8.93799C5.86442 9.56498 5.71851 10.2539 5.71851 10.9768C5.71851 11.6999 5.86443 12.3888 6.12843 13.0158L2.98706 14.6924ZM6.82966 7.74365C7.67171 6.66509 8.92531 5.92349 10.3544 5.75495L10.2358 2.19802C7.58506 2.41881 5.26768 3.81323 3.80478 5.86016L6.82966 7.74365ZM11.8541 2.21043L11.7354 5.77278C13.1329 5.97469 14.3526 6.72621 15.1684 7.80115L18.1908 5.91916C16.7553 3.87539 14.4733 2.46936 11.8541 2.21043ZM10.9769 20.3325C16.1439 20.3325 20.3327 16.1438 20.3327 10.9767C20.3327 5.8097 16.1439 1.62097 10.9769 1.62097C5.80982 1.62097 1.62109 5.8097 1.62109 10.9767C1.62109 16.1438 5.80982 20.3325 10.9769 20.3325ZM10.9769 15.6889C13.5792 15.6889 15.6889 13.5792 15.6889 10.9768C15.6889 8.37446 13.5792 6.2648 10.9769 6.2648C8.37449 6.2648 6.26484 8.37446 6.26484 10.9768C6.26484 13.5792 8.37449 15.6889 10.9769 15.6889Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.9937 14.634C19.5026 13.52 19.7864 12.2815 19.7864 10.9767C19.7864 9.67205 19.5027 8.43365 18.9938 7.31975L15.8501 8.9977C16.0984 9.60861 16.2352 10.2768 16.2352 10.9768C16.2352 11.677 16.0984 12.3451 15.85 12.9561L18.9937 14.634ZM18.1906 16.0345L15.1683 14.1526C14.3525 15.2275 13.1329 15.979 11.7354 16.1809L11.8541 19.7431C14.4732 19.4841 16.7551 18.0782 18.1906 16.0345ZM10.2358 19.7555L10.3544 16.1987C8.92534 16.0302 7.67176 15.2886 6.82971 14.2101L3.80491 16.0936C5.26782 18.1404 7.58515 19.5347 10.2358 19.7555ZM2.98706 14.6924C2.46111 13.5635 2.16742 12.3044 2.16742 10.9767C2.16742 9.64922 2.46107 8.39023 2.98696 7.26127L6.12839 8.93799C5.86442 9.56498 5.71851 10.2539 5.71851 10.9768C5.71851 11.6999 5.86443 12.3888 6.12843 13.0158L2.98706 14.6924ZM6.82966 7.74365C7.67171 6.66509 8.92531 5.92349 10.3544 5.75495L10.2358 2.19802C7.58506 2.41881 5.26768 3.81323 3.80478 5.86016L6.82966 7.74365ZM11.8541 2.21043L11.7354 5.77278C13.1329 5.97469 14.3526 6.72621 15.1684 7.80115L18.1908 5.91916C16.7553 3.87539 14.4733 2.46936 11.8541 2.21043ZM10.9769 20.3325C16.1439 20.3325 20.3327 16.1438 20.3327 10.9767C20.3327 5.8097 16.1439 1.62097 10.9769 1.62097C5.80982 1.62097 1.62109 5.8097 1.62109 10.9767C1.62109 16.1438 5.80982 20.3325 10.9769 20.3325ZM10.9769 15.6889C13.5792 15.6889 15.6889 13.5792 15.6889 10.9768C15.6889 8.37446 13.5792 6.2648 10.9769 6.2648C8.37449 6.2648 6.26484 8.37446 6.26484 10.9768C6.26484 13.5792 8.37449 15.6889 10.9769 15.6889Z" fill="#E8BA73" />
      </g>
      <defs>
        <clipPath id="clip0_5307_7152">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IcClc.defaultProps = {
  size: 22,
}
export default IcClc;
