import { ReactNode } from 'react';
import styles from './card.module.scss';
import classNames from 'classnames';

/* eslint-disable-next-line */
export interface CardProps {
  size: 'large' | 'medium' | 'small';
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export function Card(props: CardProps) {
  return (
    <div className={classNames(
      styles['container'],
      {
        'rounded-large': props.size === 'large',
        'rounded-medium': props.size === 'medium',
        'rounded-small': props.size === 'small',
      },
      'bg-secondary-coal',
      'p-5',
      'border-color-2 border-solid border-[1px]',
      // 'outline-color-3 outline outline-1 md:outline-2 xl:outline-3',
      // 'w-fit',
      {
        'cursor-pointer': props.onClick !== undefined
      },
      props.className,
    )} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export default Card;
