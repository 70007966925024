export const LOGIN_DAY1 = "LOGIN_DAY1"
export const LOGIN_DAY2 = "LOGIN_DAY2"
export const LOGIN_DAY3 = "LOGIN_DAY3"
export const LOGIN_DAY4 = "LOGIN_DAY4"
export const LOGIN_DAY5 = "LOGIN_DAY5"
export const LOGIN_DAY6 = "LOGIN_DAY6"
export const LOGIN_DAY7 = "LOGIN_DAY7"
export const ANSWER_CORRECTLY = "ANSWER_CORRECTLY"
export const ANSWER_WRONG = "ANSWER_WRONG"
export const ANSWER_DAILY_20_QUESTIONS = "ANSWER_DAILY_20_QUESTIONS"
export const ANSWER_WEEKLY_NEW_HANDS = "ANSWER_WEEKLY_NEW_HANDS"
export const DISCORD_JOINT = "DISCORD_JOINT"
export const INVITE_REWARD = "INVITE_REWARD"
export const MASTER_LESSON = "MASTER_LESSON"
export const INVITE_SUBSCRIPTION_ACTIVE = "INVITE_SUBSCRIPTION_ACTIVE"
