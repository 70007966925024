import { ReactNode } from 'react';
import styles from './badge.module.scss';
import classNames from 'classnames';

/* eslint-disable-next-line */
export interface BadgeProps {
  children: ReactNode;
  className?: string;
}

export function Badge({ className, children }: BadgeProps) {
  return (
    <div className={classNames(
      styles['container'],
      'w-fit',
      'px-2 py-0',
      'rounded-full',
      'bg-primary-golden',
      'text-black text-xs font-bold',
      className
    )}>
      {children}
    </div>
  );
}

export default Badge;
