import styles from './ic-refresh.module.scss';

/* eslint-disable-next-line */
export interface IcRefreshProps {
  size: number;
  color: string;
}

export function IcRefresh(props: IcRefreshProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} fill={props.color} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M27.1 14.313V5.396L24.158 8.34c-2.33-2.325-5.033-3.503-8.11-3.503C9.902 4.837 4.901 9.847 4.899 16c.001 6.152 5.003 11.158 11.15 11.16 4.276 0 9.369-2.227 10.836-8.478l.028-.122h-3.23l-.022.068c-1.078 3.242-4.138 5.421-7.613 5.421a8 8 0 0 1-5.691-2.359A7.993 7.993 0 0 1 8 16.001c0-4.438 3.611-8.049 8.05-8.049 2.069 0 3.638.58 5.924 2.573l-3.792 3.789H27.1z"></path>
      </g>
    </svg>
  );
}

IcRefresh.defaultProps = {
  size: 18,
  color: "#ffffff"
}

export default IcRefresh;
