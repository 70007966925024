import { POSITION_LABEL } from "../components/player-card";
import { IPlayer } from "./parse-hand-history";

export function UTGLabeling(players: IPlayer[], dealer: number, player_index: number, no_sb: boolean) {
    const cnt_of_players = players.length;
    let dealer_index = 0;
    let labels: POSITION_LABEL[] = [];
    let player_labels: POSITION_LABEL[] = [];
    switch (cnt_of_players) {
        case 2:
            labels = ["BTN", "BB"];
            player_labels = ["BTN", "BB"];
            break;
        case 3:
            labels = no_sb ? ["BTN", "BB", "CO"] : ["BTN", "SB", "BB"];
            player_labels = no_sb ? ["BTN", "BB", "CO"] : ["BTN", "SB", "BB"];
            break;
        case 4:
            labels = no_sb ? ["BTN", "BB", "UTG", "CO"] : ["BTN", "SB", "BB", "CO"];
            player_labels = no_sb ? ["BTN", "BB", "UTG", "CO"] : ["BTN", "SB", "BB", "CO"];
            break;
        case 5:
            labels = no_sb ? ["BTN", "BB", "UTG", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "CO"];
            player_labels = no_sb ? ["BTN", "BB", "UTG", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "CO"];
            break;
        case 6:
            labels = no_sb ? ["BTN", "BB", "UTG", "MP", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "HJ", "CO"];
            player_labels = no_sb ? ["BTN", "BB", "UTG", "MP", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "HJ", "CO"];
            break;
        case 7:
            labels = no_sb ? ["BTN", "BB", "UTG", "UTG+1", "MP", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "MP", "HJ", "CO"];
            player_labels = no_sb ? ["BTN", "BB", "UTG", "UTG+1", "MP", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "MP", "HJ", "CO"];
            break;
        case 8:
            labels = no_sb ? ["BTN", "SB", "BB", "UTG", "UTG+1", "MP", "MP+1", "HJ", "CO"] : ["BTN", "SB", "BB", "UTG", "UTG+1", "MP", "HJ", "CO"];
            player_labels = no_sb ? [
                "BTN",
                "BB",
                "UTG",
                "UTG+1",
                "MP",
                "MP+1",
                "HJ",
                "CO",
            ] : ["BTN", "SB", "BB", "UTG", "UTG+1", "MP", "HJ", "CO"];
            break;
        case 9:
            labels = ["BTN", "SB", "BB", "UTG", "UTG+1", "MP", "MP+1", "HJ", "CO"];
            player_labels = [
                "BTN",
                "SB",
                "BB",
                "UTG",
                "UTG+1",
                "MP",
                "MP+1",
                "HJ",
                "CO",
            ];
            break;
    }
    players.forEach((player: IPlayer, index: number) => {
        if (player.number === dealer) {
            dealer_index = index;
        }
    });
    players.forEach((player: IPlayer, index: number) => {
        let distance = (index - dealer_index + cnt_of_players) % cnt_of_players;
        let label_index = (distance + labels.length) % labels.length;
        player_labels[index] = labels[label_index];
    });
    return player_labels[player_index];
}