// import { IsEmail, IsNotEmpty, IsNumber, IsString, IsUrl } from "class-validator";

export interface EmailVerifyLink {
    email: string;
    verify_link: string;
    expires_at: number;
}

export interface ResetPasswordCode {
    email: string;
    verify_code: string;
    expires_at: number;
}

export class ResetPasswordCodeDto implements ResetPasswordCode {
    user_name: string = '';
    // @IsNotEmpty()
    // @IsEmail()
    email: string = '';
    // @IsNotEmpty()
    verify_code: string = '';
    // @IsNumber()
    // @IsNotEmpty()
    expires_at: number = 0;
}