import classNames from 'classnames';
import styles from './tags.module.scss';
import { IcClose } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface TagsProps<T> {
  active_tag?: T;
  tags: Array<T>;
  onTagHandler: (_tag: T) => void;
}

export function Tags(props: TagsProps<{ name: string, id: string }>) {
  return (
    <div className={classNames(
      styles['container'],
      "flex flex-wrap items-center gap-1"
    )}>
      {props.tags.map((tag, id) => (
        <span
          key={id}
          className={classNames(
            "flex items-center gap-1",
            "px-3 py-1",
            {
              "bg-color-3": tag.id !== props.active_tag?.id,
              "bg-primary-golden": tag.id === props.active_tag?.id
            },
            "border border-solid border-color-2",
            "text-white text-xs font-medium",
            "cursor-pointer"
          )}
          onClick={() => props.onTagHandler(tag)}
        >
          {tag.name}
        </span>
      ))}
    </div>
  );
}

export default Tags;
