import styles from './ic-search.module.scss';

/* eslint-disable-next-line */
export interface IcSearchProps {
  size: number;
}

export function IcSearch(props: IcSearchProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="9.5" stroke="white" strokeWidth="1.5"/>
      <path d="M18.5 18.5L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
}

IcSearch.defaultProps = {
  size: 24,
}

export default IcSearch;
