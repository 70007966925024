import styles from './ic-play-circle.module.scss';

/* eslint-disable-next-line */
export interface IcPlayCircleProps {
  size: number;
  color: string;
}

export function IcPlayCircle(props: IcPlayCircleProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={props.color}>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <circle cx="12" cy="12" r="10" stroke={props.color} strokeWidth="1.5"></circle>
        <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z" stroke={props.color} strokeWidth="1.5"></path>
      </g>
    </svg>
  );
}

IcPlayCircle.defaultProps = {
  size: 18,
  color: 'white'
}

export default IcPlayCircle;
