import styles from './ic-user.module.scss';

/* eslint-disable-next-line */
export interface IcUserProps {
  size: number;
}

export function IcUser(props: IcUserProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.73 13.3213C14.8183 14.8573 13.5889 15.8572 12.2139 15.8572C10.8388 15.8572 9.60946 14.8573 8.69773 13.3211C7.69055 11.6242 7.07103 9.27281 7.07103 6.89224C7.07103 4.77668 9.33534 3 12.2139 3C15.0925 3 17.3568 4.77668 17.3568 6.89224C17.3568 9.27287 16.7372 11.6243 15.73 13.3213ZM12.2141 17.6248C14.0188 17.6248 15.6323 16.3124 16.8289 14.2963C16.9196 14.1437 17.0078 13.9869 17.0935 13.8265C18.3395 14.3625 19.9287 15.3151 19.9287 16.8159C19.9287 18.3859 19.1766 19.8132 17.6722 20.2624C16.3725 20.6505 14.5378 20.9998 12.2143 20.9998C9.89074 20.9998 8.05615 20.6505 6.75641 20.2624C5.25206 19.8132 4.5 18.3859 4.5 16.8159C4.5 15.313 6.08832 14.3607 7.33404 13.8254C7.41992 13.9862 7.50829 14.1432 7.59907 14.2961C8.79571 16.3123 10.4093 17.6248 12.2141 17.6248Z" fill="#E8BA73"/>
    </svg>
  );
}

IcUser.defaultProps = {
  size: 24,
}

export default IcUser;
