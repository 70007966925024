import classNames from 'classnames';
import styles from './switch.module.scss';
import { ReactNode, useState } from 'react';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface SwitchProps<D> {
  activeValue: D;
  options: Array<{
    label: ReactNode;
    value: D;
  }>,
  onSwitchChangeHandler: (newValue: D) => Promise<boolean>;
}

export function Switch(props: SwitchProps<string>) {
  const [loading, setLoading] = useState<boolean>(false);
  const onSwitchChangeHandler = lodash.debounce((newTab: string) => {
    setLoading(true);
    props.onSwitchChangeHandler(newTab).then((value) => {
      setLoading(false);
    });
  }, 500);

  return (
    <div className={classNames(
      styles['container'],
      'flex h-7 w-fit p-1 border border-solid border-color-2 rounded-full items-center relative '
    )}>
      <div className={classNames(
        'flex flex-row items-center rounded-full',
        { 'bg-gradient-to-r from-color-2 to-color-3': loading }
      )}>
        {props.options.map((option, id) => (
          <button
            key={id}
            className={classNames(
              { 'bg-gradient-to-r from-color-2 to-color-3': props.activeValue === option.value && !loading },
              'flex h-5 p-1 md:px-3 lg:px-6 lg:w-[80px] xl:w-[104px] justify-center items-center rounded-full',
              { 'text-white': props.activeValue === option.value, 'text-secondary-grey': props.activeValue !== option.value },
              'text-[10px] leading-[12px] tracking-[2px] font-light'
            )}
            disabled={loading}
            onClick={() => onSwitchChangeHandler(option.value)}
          >
            <p className='font-inter font-light text-xs tracking-wide p-1'>{option.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Switch;
