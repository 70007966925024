import styles from './ic-rectangle-icon.module.scss';

/* eslint-disable-next-line */
export interface IcRectangleIconProps {}

export function IcRectangleIcon(props: IcRectangleIconProps) {
  return (
    <svg
      className={styles['container']}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <g filter="url(#filter0_d_103_4266)">
        <rect
          width="10.496"
          height="10.496"
          x="4"
          y="3.8"
          fill="#E8BA73"
          rx="1.749"
        ></rect>
      </g>
      <defs>
        <filter
          id="filter0_d_103_4266"
          width="17.493"
          height="17.493"
          x="0.682"
          y="0.072"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-1.749"></feOffset>
          <feGaussianBlur stdDeviation="1.749"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.909804 0 0 0 0 0.729412 0 0 0 0 0.45098 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_103_4266"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_103_4266"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default IcRectangleIcon;
