import classNames from 'classnames';
import styles from './search-box.module.scss';
import { IcLoading1, IcRefresh, IcSearch } from '@clc-v2/icons';
import lodash from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

/* eslint-disable-next-line */
export interface SearchBoxProps {
  placeHolder: string;
  search_word: string;
  onChangeHandler: (searchWord: string) => Promise<boolean>;
}

export function SearchBox(props: SearchBoxProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [search_word, set_search_word] = useState<string>('');
  const onChangeHandler = useMemo(() => lodash.debounce((_search_word) => {
    setLoading(true);
    props.onChangeHandler(_search_word).then(() => {
    }).catch((err) => {
    }).finally(() => setLoading(false));
  }, 1000), []);
  const onRefreshHandler = lodash.debounce(() => {
    set_search_word('');
    setLoading(true)
    props.onChangeHandler('').then(() => {
    }).catch((err) => {
    }).finally(() => setLoading(false));
  }, 300);
  useEffect(() => {
    set_search_word(props.search_word);
  }, [props.search_word]);
  useEffect(() => {
    if (search_word) {
      onChangeHandler(search_word)
    }
  }, [search_word]);
  return (
    <div className={classNames(styles['container'], 'flex flex-col items-start')}>
      <div className={classNames(
        'w-full h-12',
        'rounded-full',
        'flex items-center',
        'border border-solid border-secondary-grey'
      )}>
        <div className='w-12 h-12 flex justify-center items-center' title='In order to reset search word, click refresh icon'>
          <IcSearch />
        </div>
        <input className='grow shrink bg-transparent text-white placeholder:text-secondary-grey outline-none' type="text" placeholder={props.placeHolder} value={search_word} onChange={(e) => {
          set_search_word(e.target.value);
          if (e.target.value === '') {
            onRefreshHandler();
          }
        }} />
        {loading ? (
          <div className='w-12 h-12 flex justify-center items-center'>
            <IcLoading1 color='rgba(232, 186, 115, 1)' />
          </div>
        ) : search_word !== '' ? (
          <div className='w-12 h-12 flex justify-center items-center' title='Reset Search Word' onClick={onRefreshHandler}>
            <IcRefresh color='rgba(232, 186, 115, 1)' />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SearchBox;
