import styles from './ic-bookmark.module.scss';

/* eslint-disable-next-line */
export interface IcBookmarkProps {
  size: number;
}

export function IcBookmark(props: IcBookmarkProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 2.00061H3V12.0775L9 16.0006L15 12.0775V2.00061Z" stroke="#E8BA73" strokeLinejoin="round"/>
    </svg>
  );
}

IcBookmark.defaultProps = {
  size: 18,
}

export default IcBookmark;
