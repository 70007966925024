const format2Digit = (num: number) => {
    if (num < 10) {
        return `0${num}`
    } else {
        return `${num}`
    }
}

export const convertSecondsToReadable = (sec: number) => {
    const minute = Math.floor(sec / 60);
    const second = sec % 60;
    return `${format2Digit(minute)}:${format2Digit(second)}`
}