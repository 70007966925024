import { IHand } from "./parse-hand-history";

export function getPastPlayerIndex(array: IHand[], value: number, jump: number) {
    let n = 0;
    let index = 0;
    for (let i = 0; i < array.length; i ++) {
      if (array[i].player === value && i < jump) {
        n++;
      }
    }
    for (let i = 0, len = array.length; i < len; i++) {
      if (i in array && value === array[i].player && !--n) {
        index = i;
        break;
      }
    }
    return index;
}