import styles from './ic-watch.module.scss';

/* eslint-disable-next-line */
export interface IcWatchProps {
  size: number;
}

export function IcWatch(props: IcWatchProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8333 7.00081C12.8333 10.2208 10.22 12.8341 7.00001 12.8341C3.78001 12.8341 1.16667 10.2208 1.16667 7.00081C1.16667 3.78081 3.78001 1.16748 7.00001 1.16748C10.22 1.16748 12.8333 3.78081 12.8333 7.00081Z" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.16416 8.85588L7.35583 7.77671C7.04083 7.59005 6.78416 7.14088 6.78416 6.77338V4.38171" stroke="#E8BA73" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcWatch.defaultProps = {
  size: 14,
}

export default IcWatch;
