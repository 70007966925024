import classNames from 'classnames';
import styles from './login-social.module.scss';
import { IcGoogle } from '@clc-v2/icons';
import { useState } from 'react';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface LoginSocialProps {
  type: 'login' | 'register';
  google_handler?: () => void;
  slack_handler?: () => void;
  apple_handler?: () => void;
}

export function LoginSocial(props: LoginSocialProps) {
  const google_handler = lodash.debounce(() => {
    props.google_handler?.();
  }, 300);
  const slack_handler = lodash.debounce(() => {
    props.slack_handler?.();
  }, 300);
  const apple_handler = lodash.debounce(() => {
    props.apple_handler?.();
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      'flex flex-col gap-[10px]'
    )}>
      {props.google_handler && <button className='h-14 flex justify-center items-center gap-4 border border-solid hover:bg-blue-500 transition-all border-primary-golden hover:border-blue-500 rounded-full' onClick={google_handler}>
        <IcGoogle />
        <span className='text-white text-sm font-inter font-normal'>{props.type === 'login' ? 'Sign In' : 'Sign Up'} With Google</span>
      </button>}
      {props.slack_handler && <button className='h-14 flex justify-center items-center gap-4' onClick={slack_handler}>
        <IcGoogle />
        <span className='text-white text-sm font-inter font-normal'>{props.type === 'login' ? 'Sign In' : 'Sign Up'} With Google</span>
      </button>}
      {props.apple_handler && <button className='h-14 flex justify-center items-center gap-4' onClick={apple_handler}>
        <IcGoogle />
        <span className='text-white text-sm font-inter font-normal'>{props.type === 'login' ? 'Sign In' : 'Sign Up'} With Google</span>
      </button>}
    </div>
  );
}

export default LoginSocial;
