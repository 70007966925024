import classNames from 'classnames';
import styles from './marquee-text.module.scss';
import Marquee from "react-fast-marquee";

/* eslint-disable-next-line */
export interface MarqueeTextProps {
  contents: Array<string>;
}

export function MarqueeText(props: MarqueeTextProps) {
  return (
    <Marquee className={classNames(styles['container'], 'w-full overflow-x-hidden overflow-y-hidden')} autoFill>
      {props.contents.map((content, id) =>
        <span
          key={id}
          className={classNames(
            'h-8 py-1 px-4',
            'border-l border-solid border-primary-golden border-r-primary-golden',
            'text-xs text-white font-light leading-[14px] tracking-[2px]',
            'bg-color-2',
            'align-middle',
            'bg-gradient-to-r from-color-2 to-color-3'
          )}>
          {content}
        </span>
      )}
    </Marquee>
  );
}

export default MarqueeText;
