import classNames from 'classnames';
import styles from './ribbon.module.scss';
import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface RibbonProps {
  content: ReactNode;
}

export function Ribbon(props: RibbonProps) {
  return (
    <div className={classNames(
      styles['container'],
      'absolute right-0 top-0',
      'w-32 h-32',
      'overflow-hidden',
      'flex justify-center items-center',
      'z-50'
    )}>
      <div className='w-[141%] h-[141%] transform rotate-[45deg] origin-center relative flex justify-center items-center'>
        <div className='w-[500px] py-1 text-center bg-primary-golden text-black text-xs mb-10'>
          {props.content}
        </div>
      </div>
    </div>
  );
}

export default Ribbon;
