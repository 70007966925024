import { LoginSocial, SignupEmailForm } from "@clc-v2/uis"
import { API_BACKEND_ENDPOINT, COOKIE_DOMAIN } from "../config"
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";
import { useSearchParams } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from "react";
import { AuthCookieName, EncryptionKey, defaultRedirectUrl } from "./LoginForm";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const SignUpForm = () => {
    const [searchParams] = useSearchParams();
    const [referer_email, set_referer_email] = useState<string>('');
    const [utm_source, set_utm_source] = useState<string>('');
    const [utm_medium, set_utm_medium] = useState<string>('');
    const [utm_campaign, set_utm_campaign] = useState<string>('');
    const [utm_content, set_utm_content] = useState<string>('');
    const [utm_term, set_utm_term] = useState<string>('');
    const signup_handler = (param: {
        given_name?: string,
        family_name?: string,
        user_name: string,
        picture: string,
        email: string,
        password: string,
        coupon?: string,
    }) => {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(
                `${API_BACKEND_ENDPOINT}/auth/register-email-and-password?${referer_email ? `referer_email=${referer_email}&` : ''}${utm_source ? `utm_source=${utm_source}&` : ''}${utm_medium ? `utm_medium=${utm_medium}&` : ''}${utm_campaign ? `utm_campaign=${utm_campaign}&` : ''}${utm_content ? `utm_content=${utm_content}&` : ''}${utm_term ? `utm_term=${utm_term}&` : ''}`,
                {
                    ...param,
                    rememberMe: false
                }
            ).then((res: { data: { accessToken: string } }) => {
                const encryptedAccessToken = CryptoJS.AES.encrypt(res.data.accessToken, EncryptionKey).toString();
                Cookie.set(AuthCookieName, encryptedAccessToken, {
                    domain: COOKIE_DOMAIN
                })
                setTimeout(() => {
                    resolve(true);
                    const redirectUrl = searchParams.get('redirect') ?? defaultRedirectUrl;
                    window.location.href = redirectUrl
                }, 500);
            }).catch((err: AxiosError<{ status: string, message: string[], }>) => {
                if (err.response) {
                    // if axios error
                    reject(err.response.data.message);
                } else {
                    // if network error
                    reject([err.message]);
                }
            })
        })
    }
    const google_signup_handler = useGoogleLogin({
        flow: 'implicit',
        onSuccess: async (codeResponse) => {
            axios.post(
                `${API_BACKEND_ENDPOINT}/auth/register-google?${referer_email ? `referer_email=${referer_email}&` : ''}${utm_source ? `utm_source=${utm_source}&` : ''}${utm_medium ? `utm_medium=${utm_medium}&` : ''}${utm_campaign ? `utm_campaign=${utm_campaign}&` : ''}${utm_content ? `utm_content=${utm_content}&` : ''}${utm_term ? `utm_term=${utm_term}&` : ''}`,
                codeResponse
            ).then((res: { data: { accessToken: string } }) => {
                const encryptedAccessToken = CryptoJS.AES.encrypt(res.data.accessToken, EncryptionKey).toString();
                Cookie.set(AuthCookieName, encryptedAccessToken)
                setTimeout(() => {
                    const redirectUrl = searchParams.get('redirect') ?? defaultRedirectUrl;
                    window.location.href = redirectUrl
                }, 500);
            }).catch((err: AxiosError<{ status: string, message: string }>) => {
                if (err.response) {
                    // if axios error
                    toast.warn(err.response.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    // if network error
                    toast.warn(err.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        },
        onError: errorResponse => console.log(errorResponse),
    });
    useEffect(() => {
        const _referer_email = searchParams.get('referer_email');
        if (_referer_email) {
            set_referer_email(_referer_email);
        }
    }, [searchParams.get('referer_email')]);
    useEffect(() => {
        if (searchParams.get('utm_source')) {
            set_utm_source(searchParams.get('utm_source') ?? '');
        }
    }, [searchParams.get('utm_source')]);
    useEffect(() => {
        if (searchParams.get('utm_medium')) {
            set_utm_medium(searchParams.get('utm_medium') ?? '');
        }
    }, [searchParams.get('utm_medium')]);
    useEffect(() => {
        if (searchParams.get('utm_campaign')) {
            set_utm_campaign(searchParams.get('utm_campaign') ?? '');
        }
    }, [searchParams.get('utm_campaign')]);
    useEffect(() => {
        if (searchParams.get('utm_content')) {
            set_utm_content(searchParams.get('utm_content') ?? '');
        }
    }, [searchParams.get('utm_content')]);
    useEffect(() => {
        if (searchParams.get('utm_term')) {
            set_utm_term(searchParams.get('utm_term') ?? '');
        }
    }, [searchParams.get('utm_term')]);
    return (
        <div className="w-full">
            {referer_email && <p className="break-all line-clamp-1 text-primary-golden mb-2" title={`You are invited by ${referer_email}`}>Invited By {referer_email}</p>}
            <div className="hidden items-center gap-2">
                <div className="grow shrink border border-solid border-primary-golden"></div>
                <div className="grow-0 shrink-0 text-sm text-text-op70 font-normal font-inter">Or better yet...</div>
                <div className="grow shrink border border-solid border-primary-golden"></div>
            </div>
            <SignupEmailForm
                signup_handler={signup_handler}
            // forgot_passwordHandler={() => null}
            />
            <div className="w-full h-4" />
            <LoginSocial
                type="register"
                google_handler={google_signup_handler}
            />
        </div>
    )
}