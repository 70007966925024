import classNames from 'classnames';
import styles from './social-links.module.scss';
import { IcFacebook, IcInstagram, IcSpotify, IcTiktok, IcTwitter, IcYoutube } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface SocialLinksProps {
  instagramLink?: string;
  twitterLink?: string;
  spotifyLink?: string;
  facebookLink?: string;
  tiktokLink?: string;
  youtubeLink?: string;
}

export function SocialLinks(props: SocialLinksProps) {
  return (
    <div className={classNames(
      styles['container'],
      'flex items-center gap-3'
    )}>
      {props.instagramLink && <button onClick={() => window.open(props.instagramLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcInstagram />
      </button>}
      {props.twitterLink && <button onClick={() => window.open(props.twitterLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcTwitter />
      </button>}
      {props.spotifyLink && <button onClick={() => window.open(props.spotifyLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcSpotify />
      </button>}
      {props.tiktokLink && <button onClick={() => window.open(props.tiktokLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcTiktok />
      </button>}
      {props.youtubeLink && <button onClick={() => window.open(props.youtubeLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcYoutube />
      </button>}
      {props.facebookLink && <button onClick={() => window.open(props.facebookLink, '_blank')} className='w-10 h-10 rounded-full border border-solid border-secondary-grey bg-transparent hover:bg-primary-golden transition-all flex justify-center items-center'>
        <IcFacebook />
      </button>}
    </div>
  );
}

export default SocialLinks;
