import styles from './ic-chip.module.scss';

/* eslint-disable-next-line */
export interface IcChipProps {
  size: number;
}

export function IcChip(props: IcChipProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 25 / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_727_2600)">
      <ellipse cx="11.9998" cy="12" rx="6.99978" ry="7" fill="#0F0F0F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3129 9.30348C13.676 8.78125 12.8778 8.49602 12.0542 8.4964C11.1108 8.49711 10.2062 8.87206 9.53888 9.53899C8.95651 10.1214 8.59411 10.8877 8.51343 11.7073C8.43275 12.527 8.63879 13.3493 9.09643 14.034C9.55407 14.7188 10.235 15.2236 11.0232 15.4626C11.8113 15.7015 12.658 15.6598 13.4188 15.3444C14.1796 15.029 14.8075 14.4596 15.1956 13.7331C15.5836 13.0067 15.7077 12.1681 15.5467 11.3604C15.3858 10.5526 14.9497 9.82572 14.3129 9.30348ZM11.1647 17.311C10.3725 17.1777 9.62104 16.866 8.96694 16.3997L7.74409 17.6225C8.71826 18.3589 9.88952 18.8483 11.1647 18.9976V17.311ZM12.9437 18.9823V17.3124C13.7367 17.1803 14.4889 16.8686 15.1429 16.401L16.3119 17.57C15.3549 18.3119 14.2019 18.813 12.9437 18.9823ZM18.9841 12.9439C18.8143 14.2024 18.3126 15.3556 17.57 16.3125L16.4007 15.1432C16.8682 14.4892 17.18 13.7369 17.312 12.9439H18.9841ZM17.6247 7.7416C18.3615 8.71646 18.8511 9.88867 19 11.1649H17.3107C17.1773 10.3727 16.8657 9.62116 16.3994 8.96704L17.6247 7.7416ZM16.3825 6.46817L15.1416 7.7092C14.4875 7.24277 13.7359 6.93111 12.9437 6.79775V5.00037C14.233 5.17392 15.4119 5.69579 16.3825 6.46817ZM6.47068 16.3803L7.70913 15.1419C7.24273 14.4878 6.93108 13.7361 6.79772 12.9439H5.00329C5.17718 14.2322 5.69884 15.4102 6.47068 16.3803ZM8.96567 7.70796C9.61955 7.24032 10.3718 6.9285 11.1647 6.79639V5C9.89948 5.15663 8.70036 5.65329 7.6949 6.43715L8.96567 7.70796ZM6.79636 11.1649C6.92847 10.3719 7.24027 9.61966 7.7079 8.96576L6.43694 7.69477C5.65308 8.70031 5.15649 9.89955 5 11.1649H6.79636Z" fill="#E8BA73"/>
      </g>
      <defs>
      <filter id="filter0_d_727_2600" x="3" y="5" width="18" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_727_2600"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_727_2600" result="shape"/>
      </filter>
      </defs>
    </svg>
  );
}

IcChip.defaultProps = {
  size: 24
}

export default IcChip;
