import styles from './ic-left.module.scss';

/* eslint-disable-next-line */
export interface IcLeftProps {
  size: number;
}

export function IcLeft(props: IcLeftProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 8 / 5} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.19998 0.799999L0.999982 4L4.19998 7.2" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IcLeft.defaultProps = {
  size: 5
}

export default IcLeft;
