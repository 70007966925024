import styles from './clc-logo.module.scss';
// import logoSvg from './clc-logo.svg';
import classNames from 'classnames';

const logoPath = `${process.env.NX_DOMAIN}/public/clc-logo2.0.png`

/* eslint-disable-next-line */
export interface ClcLogoProps {
  className?: string;
}

export function ClcLogo(props: ClcLogoProps) {
  return (
    <div className={classNames(
      styles['container'],
      // 'w-32',
      // 'h-24',
      'rounded-full',
      // 'bg-gradient-to-b from-linear-graident-4 to-transparent',
      'flex justify-center items-center',
      'relative',
      props.className
    )}>
      <img className='w-full h-full' src={logoPath} alt="CLC" />
    </div>
  );
}

export default ClcLogo;
