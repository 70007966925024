import styles from './ic-twitter.module.scss';

/* eslint-disable-next-line */
export interface IcTwitterProps {
  size: number;
}

export function IcTwitter(props: IcTwitterProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 19} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2988 2.30774C17.6363 2.65774 16.9222 2.88774 16.1822 2.99774C16.9394 2.46774 17.5245 1.62774 17.7998 0.617739C17.0856 1.11774 16.294 1.46774 15.4594 1.66774C14.7797 0.807739 13.8246 0.307739 12.7405 0.307739C10.7185 0.307739 9.06651 2.22774 9.06651 4.59774C9.06651 4.93774 9.10093 5.26774 9.16116 5.57774C6.09806 5.39774 3.37053 3.68774 1.55504 1.09774C1.23669 1.72774 1.056 2.46774 1.056 3.24774C1.056 4.73774 1.70131 6.05774 2.6994 6.80774C2.0885 6.80774 1.52063 6.60774 1.02158 6.30774V6.33774C1.02158 8.41774 2.295 10.1577 3.98143 10.5477C3.43999 10.7199 2.87157 10.7439 2.32082 10.6177C2.55451 11.4702 3.0122 12.2161 3.62953 12.7507C4.24687 13.2852 4.99281 13.5814 5.7625 13.5977C4.45778 14.7982 2.84051 15.4471 1.17646 15.4377C0.883914 15.4377 0.591371 15.4177 0.298828 15.3777C1.93363 16.5977 3.87818 17.3077 5.9604 17.3077C12.7405 17.3077 16.4661 10.7677 16.4661 5.09774C16.4661 4.90774 16.4661 4.72774 16.4575 4.53774C17.1803 3.93774 17.7998 3.17774 18.2988 2.30774Z" fill="white" />
    </svg>
  );
}

IcTwitter.defaultProps = {
  size: 19,
}

export default IcTwitter;
