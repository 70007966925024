import classNames from 'classnames';
import styles from './card-lesson-1.module.scss';
import { useEffect, useState } from 'react';
import Card from '../card/card';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface CardLesson1Props {
  topic: string;
  title: String;
  description: string;
  totalLessonCount: number;
  passedLessonCount: number;
  onClickHandler: () => Promise<boolean>;
}

export function CardLesson1(props: CardLesson1Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [cnt_of_questions, set_cnt_of_questions] = useState<number>(0);
  const [cnt_of_passed_questions, set_cnt_of_passed_questions] = useState<number>(0);
  const [displayCount, setDisplayCount] = useState<number>(0);
  useEffect(() => {
    let timer: NodeJS.Timer;
    if (props.passedLessonCount !== displayCount) {
      if (props.passedLessonCount > displayCount) {
        timer = setTimeout(() => setDisplayCount((_prev) => _prev + 1), 80);
      } else if (props.passedLessonCount < displayCount) {
        timer = setTimeout(() => setDisplayCount((_prev) => _prev - 1), 80);
      }
    }
    return () => clearTimeout(timer);
  }, [props.passedLessonCount, displayCount]);
  const onClickHandler = lodash.debounce(() => {
    setLoading(true);
    props.onClickHandler().then(() => setLoading(false));
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      'relative',
      {
        'cursor-pointer': !loading
      },
      'mt-16'
    )} onClick={onClickHandler}>
      <div className='absolute z-20 left-1/2 -top-16 transform -translate-x-1/2 w-[120px] h-[120px] bg-gradient-to-br from-primary-carbon to-[rgba(21,23,26,0.25)] rounded-full flex justify-center items-center'>
        <div className='w-[105px] h-[105px] bg-black bg-opacity-40 rounded-full flex justify-center items-center'>
          <div className='w-[100px] h-[100px] bg-black rounded-full p-1'>
            <CircularProgressbarWithChildren
              value={displayCount / props.totalLessonCount * 100}
              strokeWidth={6}
              styles={buildStyles({
                textColor: "rgba(255, 255, 255, 1)",
                pathColor: displayCount / (props.totalLessonCount ?? 1) >= 0.75 ? "#759A47" : displayCount / (props.totalLessonCount ?? 1) >= 0.5 ? "#E8BA73" : "#C75350",
                trailColor: "rgba(0, 0, 0, 1)"
              })}
            >
              <div className='flex items-end text-white font-medium'>
                <span className='text-[32px]'>{(displayCount / (props.totalLessonCount ?? 1) * 100).toFixed(0)}</span>
                <span className='text-base self-center mt-3'>%</span>
              </div>
              <div className='hidden items-center text-xs font-semibold'>
                <span className='text-white'>{displayCount}</span><span className='text-secondary-grey'>/{props.totalLessonCount}</span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
      <Card size='small' className='w-full h-96 flex flex-col gap-5 pt-20 pb-10 px-10'>
        <h4 className='text-xs font-light uppercase leading-[14px] tracking-[2px] text-primary-golden'>{props.topic}</h4>
        <h2 className='text-2xl font-medium leading-[28px] tracking-[-0.2px] text-white'>{props.title}</h2>
        <div className='shrink grow overflow-auto thin-scrollbar'>
          <p className='text-sm font-normal text-secondary-grey leading-[21px] break-words'>{props.description}</p>
        </div>
      </Card>
    </div>
  );
}

export default CardLesson1;
