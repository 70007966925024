import styles from './ic-horizontal-separator.module.scss';

/* eslint-disable-next-line */
export interface IcHorizontalSeparatorProps {}

export function IcHorizontalSeparator(props: IcHorizontalSeparatorProps) {
  return (
    <svg
      className={styles['container']}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="3"
      fill="none"
      viewBox="0 0 32 3"
    >
      <path
        stroke="url(#paint0_linear_31_788)"
        strokeWidth="2"
        d="M0.5 1.05L31.5 1.05"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_31_788"
          x1="0.5"
          x2="31.421"
          y1="3.05"
          y2="0.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8BA73" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#E8BA73" stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IcHorizontalSeparator;
