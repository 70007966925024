import { IcLoading2 } from '@clc-v2/icons';
import styles from './clc-spinner.module.scss';
// import CLCLogo from './clc-logo.png';
import classNames from 'classnames';

const logoPath = `${process.env.NX_DOMAIN}/public/clc-logo2.0.png`

/* eslint-disable-next-line */
export interface ClcSpinnerProps {
  text?: string;
}

export function ClcSpinner(props: ClcSpinnerProps) {
  return (
    <div className="flex flex-col items-center gap-6">
      <div className={classNames(
        styles['container'],
        // "animate-bounce",
        "w-56 h-24 md:w-80 md:h-36",
      )}>
        <img className="w-full h-full" src={logoPath} alt="Logo" />
      </div>
      {props.text && (
        <span className="text-white font-inter text-xl flex items-center gap-4">
          {props.text}
          <IcLoading2 size={36} />
        </span>
      )}
    </div>
  );
}

export default ClcSpinner;
