import classNames from 'classnames';
import styles from './card-article.module.scss';
import { useEffect, useState } from 'react';
import { IcLeft, IcRight } from '@clc-v2/icons';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface CardArticleProps {
  bannerImages: Array<string>;
  title: string;
  date: string;
  readNowHandler: () => Promise<boolean>;
}

export function CardArticle(props: CardArticleProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [bannerIndex, setBannerIndex] = useState<number>(0);

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setBannerIndex((_prev) => props.bannerImages.length - 1 === _prev ? 0 : _prev + 1)
    }, 3000);
    return () => clearInterval(intervalTimer);
  }, [props.bannerImages]);

  const readNowHandler = lodash.debounce(() => {
    setLoading(true);
    props.readNowHandler().then((value) => {
      setLoading(false);
    });
  }, 100);

  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      'flex flex-col gap-[20px]',
      'bg-black-dark',
      'rounded-xl'
    )}>
      <div className='rounded-xl w-full h-72 flex justify-center items-center overflow-hidden'>
        {props.bannerImages.length > 0
          ? <img className='min-w-full min-h-full object-cover' src={props.bannerImages[bannerIndex]} alt='banner' />
          : <div className='w-full h-full flex justify-center items-center bg-secondary-transparent-coal'>No Image</div>}
      </div>
      <div className='flex flex-col gap-4'>
        <span className='text-secondary-grey text-light text-xs font-light tracking-wide'>{props.date}</span>
        <div className='w-full h-24 overflow-hidden'>
          <h2 className='text-white text-2xl font-medium leading-[28px] tracking-[-0.2px] line-clamp-3'>{props.title}</h2>
        </div>
        <button className={`w-full h-9 px-4 rounded-full border border-solid border-color-2 flex justify-between items-center ${loading ? 'animate-pulse bg-slate-700 ' : ''}`} disabled={loading} onClick={readNowHandler}>
          <span className='text-primary-golden text-xs font-light'>READ NOW</span>
          <IcRight size={9} color='rgba(232, 186, 115, 1)' />
        </button>
      </div>
    </div>
  );
}

export default CardArticle;
