import { IcFly, IcGraph, IcHome, IcLogout, IcPlay, IcTool, IcVideo } from '@clc-v2/icons';
import styles from './left-side-panel.module.scss';
import classNames from 'classnames';

/* eslint-disable-next-line */
export interface LeftSidePanelProps {
  logoutHandler: () => void;
  homeHandler: () => void;
  aiLearningHandler: () => void;
  pickYourPathHandler: () => void;
  videoLibraryHandler: () => void;
  performanceHandler: () => void;
  clcStoreHandler: () => void;
  studyByHandNumberHandler: () => void;
  accountHandler: () => void;
  newHandsHandler: () => void;
  learningHistoryHandler: () => void;
  tutorialHandler: () => void;
}

export function LeftSidePanel(props: LeftSidePanelProps): JSX.Element {
  return (
    <div className={classNames(
      styles['container'],
      'w-72',
      'rounded-3xl',
      'border border-solid border-color-2',
      'px-10 py-2',
      'relative',
      'flex flex-col justify-between',
      'backdrop-blur'
    )} style={{
      background: "radial-gradient(99.31% 94.34% at 49.88% -2.58%, rgba(232, 186, 115, 0.15) 0%, rgba(232, 186, 115, 0.00) 72.18%), rgba(21, 23, 26, 1)"
    }}>
      <div className='grid grid-cols-2'>
        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.homeHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcHome />
          </div>
          <span className='text-xs text-white font-normal text-center'>Home</span>
        </div>
        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.aiLearningHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcPlay />
          </div>
          <span className='text-xs text-white font-normal text-center'>AI Learning</span>
        </div>
        <div className='h-[1px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.pickYourPathHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcFly />
          </div>
          <span className='text-xs text-white font-normal text-center'>Pick Your Path</span>
        </div>
        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.videoLibraryHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcVideo />
          </div>
          <span className='text-xs text-white font-normal text-center'>Video Library</span>
        </div>
        <div className='h-[1px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.performanceHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcGraph />
          </div>
          <span className='text-xs text-white font-normal text-center'>Performance</span>
        </div>
        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.clcStoreHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcPlay />
          </div>
          <span className='text-xs text-white font-normal text-center'>CLC Store</span>
        </div>
        <div className='h-[1px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.studyByHandNumberHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcHome />
          </div>
          <span className='text-xs text-white font-normal text-center'>Study By Hand Number</span>
        </div>

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.accountHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcTool />
          </div>
          <span className='text-xs text-white font-normal text-center'>Account</span>
        </div>
        <div className='h-[1px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.newHandsHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcTool />
          </div>
          <span className='text-xs text-white font-normal text-center'>New Hands</span>
        </div>

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.learningHistoryHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcGraph />
          </div>
          <span className='text-xs text-white font-normal text-center'>Learning History</span>
        </div>
        <div className='h-[1px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />

        <div className='flex flex-col justify-center items-center gap-4 my-4 cursor-pointer' onClick={props.tutorialHandler}>
          <div className='w-12 h-12 rounded-2xl flex justify-center items-center border border-solid border-color-2'>
            <IcVideo />
          </div>
          <span className='text-xs text-white font-normal text-center'>Tutorial</span>
        </div>
      </div>

      <div className='w-full flex flex-col'>
        <div className='h-[2px] col-span-2 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-10' />
        <div className='w-fit mx-auto h-12 flex justify-center items-center gap-4 cursor-pointer' onClick={props.logoutHandler}>
          <IcLogout />
          <span className='font-bold text-sm text-white'>Log Out</span>
        </div>
      </div>
    </div>
  );
}

export default LeftSidePanel;
