import classNames from 'classnames';
import styles from './divider.module.scss';

/* eslint-disable-next-line */
export interface DividerProps {}

export function Divider(props: DividerProps) {
  return (
    <div className={classNames(
      styles['container'],
      "container mx-auto h-[2px] bg-gradient-to-r from-transparent via-white-light to-transparent"
    )} />
  );
}

export default Divider;
