export interface IPlayer {
    number: number,
    name: string,
    initAmount: number,
    cards: string[],
}

export interface ITableInfo {
    dealer: number,
    sb?: number,
    bb: number,
    ante: number,
    players: number, // count of players
    pot: number,
}

export interface IHand {
    player: number,
    totalChips: number,
    playerName: string,
    action: string,
    copyAction: string,
    amount: number,
    copyAmount: number,
    displayAmount: number,
    cards: string[],
    tableAction: string,
}

export function parseHandHistory(record: string): {
    flop: string[],
    hands: Array<IHand>,
    noHandHistory: boolean,
    players: Array<IPlayer>,
    tableInfo?: ITableInfo,
} {
    if (record === "" || record === null) {
        return {
            noHandHistory: true,
            tableInfo: undefined,
            players: [],
            hands: [],
            flop: [],
        };
    }
    const seatRegex = /Seat\s+[#?|\']?(?<seat_number>\d+)\'?:\s+(?<seat_name>[a-zA-Z0-9!@#\$%\^\&*\)\(+=._/\-\s\']*)\s+\(\s*(?<seat_amount>\S+(\s+\S+)*)\s*\)/;
    const seatAmountRegex = /(?<seat_amount>(\d{1,3}(\,\d{1,3})*)*(\.\d{1,2})?)/;
    const flopRegex = /\*+\s+(?<description>\w+(\s+\w+)*)\s+\*+\s+\[\s*(?<cards_0>[a-zA-Z0-9]{2}(\s*\,?\s*[a-zA-Z0-9]{2})*)\s*\](\s+\[\s*(?<cards_1>[a-zA-Z0-9]{2}(\s*\,?\s*[a-zA-Z0-9]{2})*)\s*\])?(\s+\[\s*(?<cards_2>[a-zA-Z0-9]{2}(\s*\,?\s*[a-zA-Z0-9]{2})*)\s*\]*)?/;
    const records = record.split("\n");
    const players: Array<IPlayer> = [];
    let flop: string[] = [];
    const mapPlayers: { [key: string]: IPlayer } = {};
    const hands: Array<IHand> = [];
    for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const result = seatRegex.exec(line)?.groups;
        if (result) {
            const amountResult = seatAmountRegex.exec(result.seat_amount)?.groups;
            if (amountResult) {
                const tmp = {
                    number: parseInt(result.seat_number),
                    name: result.seat_name,
                    initAmount: parseInt(amountResult.seat_amount.replace(/,/g, ""), 10),
                    cards: [] as string[],
                };
                players.push(tmp);
                mapPlayers[tmp.name] = tmp;
            }
        }
    }

    const words = players.map((p) => `\\b${p.name.replace(" ", "\\s+")}\\b`).join("|");
    const handRegex = new RegExp(`(?<seat_name>${words})\\:?\\s+(?<hand>[A-Za-z]+(\\s+[A-Za-z]+)*)(\\s*)\\[?(\\s*)(?<seat_amount>(([0-9]{1,3}(\\,[0-9]{1,3})*)*(\\.[0-9]{1,2})?)|[a-zA-Z0-9\\,\\$\\s]*)?\\s*(?<to>([a-zA-Z]*))?\s*(?<seat_amount_2>(([0-9]{1,3}(\\,[0-9]{1,3})*)*(\\.[0-9]{1,2})?)|[0-9\\,\\$\\s]*)?(\\s*)\\]?(?<other>[A-Za-z]+(\\s+[A-Za-z]+)*)?`);
    //\s*${words}\\s+\\[*\\s*(?<cards>[a-zA-Z0-9]{2}(\\,?\\s*[a-zA-Z0-9]{2})*)\\s*\\]*
    const dealRegex = new RegExp(`\\bDealt\\b\\s+\\bto\\b\\s+(?<seat_name>${words})\\s+\\[*\\s*(?<cards>[a-zA-Z0-9]{2}(\\,?\\s*[a-zA-Z0-9]{2})*)\\s*\\]*`);

    let flopIndex = 0;
    let turnIndex = 0;
    let riverIndex = 0;
    for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const result = flopRegex.exec(line)?.groups;
        if (result && result.description) {
            if (result.description === "Dealing Flop") {
                flop = result.cards_0.replace(/ /g, "").split(",");
                flopIndex = i;
            } else if (result.description === "FLOP") {
                flop = result.cards_0.split(" ");
                flopIndex = i;
            } else if (result.description === "Dealing Turn") {
                flop.push(result.cards_0);
                turnIndex = i;
            } else if (result.description === "TURN") {
                flop.push(result.cards_1);
                turnIndex = i;
            } else if (result.description === "Dealing River") {
                flop.push(result.cards_0);
                riverIndex = i;
            } else if (result.description === "RIVER") {
                flop.push(result.cards_1);
                riverIndex = i;
            }
        }
    }

    for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const result = dealRegex.exec(line)?.groups;
        if (result) {
            if (mapPlayers[result.seat_name]) {
                mapPlayers[result.seat_name].cards = result.cards.split(/, | \s*/);
            }
        }
    }
    let bb = 0;
    let sb = 0;
    let ante = 0;
    let me: any;
    let lastAmount: Record<number | 'max', number> = {
        max: 0
    };
    const amount_remain = [];
    for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const result = handRegex.exec(line)?.groups;
        if (result) {
            if (
                result.hand.trim() === "posts small blind" ||
                result.hand.trim() === "posts the small blind"
            ) {
                sb = parseInt(result.seat_amount.replace(/,/g, ""), 10);
            } else if (
                result.hand.trim() === "posts big blind" ||
                result.hand.trim() === "posts the big blind"
            ) {
                bb = parseInt(result.seat_amount.replace(/,/g, ""), 10);
            } else if (
                result.hand.trim() === "posts ante" ||
                result.hand.trim() === "posts the ante"
            ) {
                ante = parseInt(result.seat_amount.replace(/,/g, ""), 10);
            }
            if (mapPlayers[result.seat_name]?.cards?.length > 0) {
                me = mapPlayers[result.seat_name];
            }
            if (
                result.hand.trim() === "posts ante" ||
                result.hand.trim() === "posts the ante" ||
                result.hand.trim() === "posts big blind" ||
                result.hand.trim() === "posts the big blind" ||
                result.hand.trim() === "posts small blind" ||
                result.hand.trim() === "posts the small blind" ||
                result.hand.trim() === "calls" ||
                result.hand.trim() === "raises" ||
                result.hand.trim() === "raises to" ||
                result.hand.trim() === "checks" ||
                result.hand.trim() === "folds" ||
                result.hand.trim() === "bets" ||
                result.hand.trim() === "is allIn"
            ) {

                if (result.hand.trim() === "bets") lastAmount = { max: 0 }
                // if(result.hand.trim() === "raises" && reul)

                const player = mapPlayers[result.seat_name].number;
                const totalChips = mapPlayers[result.seat_name].initAmount;
                const playerName = result.seat_name;
                if (lastAmount[player] == undefined) lastAmount[player] = 0;
                if (amount_remain[player] == undefined) amount_remain[player] = totalChips;
                let action = result.hand.trim();
                let copyAction = result.hand.trim();
                let amount = result.seat_amount ? parseInt(result.seat_amount.replace(/,/g, ""), 10) : 0;
                if (result.other?.includes("allIn")) {
                    result.hand === "raises";
                    amount = amount_remain[player];
                    action = "all-in";
                    copyAction = "all-in";
                }
                if (result.seat_amount_2 && result.seat_amount_2 != ' ') {
                    amount = parseInt(result.seat_amount_2.replace(/,/g, ""), 10)
                    result.hand = "raises to";
                    action = "raises to";
                    copyAction = "raises to"
                }
                if (result.other?.includes("allIn")) {
                    action = "all-in";
                    copyAction = "all-in"
                }
                if (result.hand.trim() === "raises to") {
                    amount = amount - lastAmount[player];
                }
                const copyAmount = amount;
                const displayAmount = (result.hand.trim() == "calls" ? lastAmount.max : lastAmount[player] + amount);

                const cards = mapPlayers[result.seat_name].cards;
                const tableAction = "";
                hands.push({
                    action: action,
                    amount: amount,
                    cards: cards,
                    copyAction: copyAction,
                    copyAmount: copyAmount,
                    displayAmount: (amount == 0) ? 0 : displayAmount,
                    player: player,
                    playerName: playerName,
                    tableAction: tableAction,
                    totalChips: totalChips,
                });
                lastAmount[player] = (result.hand.trim() === "posts ante" || result.hand.trim() === "posts the ante") ? 0 : displayAmount;
                if (lastAmount.max < lastAmount[player]) lastAmount.max = lastAmount[player];
                amount_remain[player] = amount_remain[player] - hands[hands.length - 1].amount;
            }

            if (flopIndex > 0 && flopIndex - 1 === i) {
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "flop",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
            } else if (turnIndex > 0 && turnIndex - 1 === i) {
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "turn",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
            } else if (riverIndex > 0 && riverIndex - 1 === i) {
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
                hands.push({
                    action: "",
                    amount: 0,
                    cards: [], // me.cards,
                    copyAction: "",
                    tableAction: "river",
                    copyAmount: 0,
                    displayAmount: 0,
                    player: 0,
                    playerName: "",
                    totalChips: 0,
                });
            }
        }
    }

    if (me) {
        hands.push({
            player: parseInt(me.number),
            totalChips: me.initAmount,
            playerName: me.seat_name,
            action: "?",
            copyAction: "?",
            amount: 0,
            copyAmount: 0,
            displayAmount: 0,
            cards: me.cards,
            tableAction: "",
        });
    }
    const dealerIndex = record.search("is the button");
    const dealer = parseInt(record[dealerIndex - 2]);
    const no_sb = record.includes('There is no Small Blind in this hand as the Big Blind of the previous hand left the table');
    console.log('Small Blind ', dealer, sb, bb, ante)
    const tableInfo: ITableInfo = {
        dealer,
        sb: no_sb ? undefined : sb,
        bb,
        ante,
        players: players.length,
        pot: 0,
    };

    return {
        noHandHistory: false,
        tableInfo,
        players,
        hands,
        flop,
    };
}