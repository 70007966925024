import styles from './ic-email.module.scss';

/* eslint-disable-next-line */
export interface IcEmailProps {
  size: number;
}

export function IcEmail(props: IcEmailProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_926_480)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 7.5C2.5 6.39543 3.39543 5.5 4.5 5.5H20.5C21.6046 5.5 22.5 6.39543 22.5 7.5V17.5C22.5 18.6046 21.6046 19.5 20.5 19.5H4.5C3.39543 19.5 2.5 18.6046 2.5 17.5V7.5ZM4.92426 7.07574L12.2814 14.4329C12.4022 14.5536 12.5979 14.5536 12.7186 14.4329L20.0757 7.07574C20.3101 6.84142 20.6899 6.84142 20.9243 7.07574C21.1586 7.31005 21.1586 7.68995 20.9243 7.92426L16.1083 12.7402L20.9014 17.054C21.1477 17.2757 21.1677 17.6551 20.946 17.9014C20.7243 18.1477 20.3449 18.1677 20.0986 17.946L15.2586 13.5899L13.5671 15.2814C12.9778 15.8708 12.0223 15.8708 11.4329 15.2814L9.74142 13.5899L4.90138 17.946C4.65507 18.1677 4.2757 18.1477 4.05402 17.9014C3.83235 17.6551 4.0232 17.2757 4.09862 17.054L8.89171 12.7402L4.07574 7.92426C3.84142 7.68995 3.84142 7.31005 4.07574 7.07574C4.31005 6.84142 4.68995 6.84142 4.92426 7.07574Z" fill="#E8BA73"/>
      </g>
      <defs>
      <clipPath id="clip0_926_480">
      <rect width="20" height="14" fill="white" transform="translate(2.5 5.5)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

IcEmail.defaultProps = {
  size: 24,
}

export default IcEmail;
