import styles from './ic-double-right.module.scss';

/* eslint-disable-next-line */
export interface IcDoubleRightProps {
  size: number;
}

export function IcDoubleRight(props: IcDoubleRightProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 527.42 527.42" xmlSpace="preserve">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M4.925,271.773l263.77,216.474c6.591,5.288,9.593,3.137,6.707-4.807l-69.104-206.862 c-2.889-7.94-2.861-20.809,0.061-28.736l68.982-203.849c2.923-7.929-0.082-10.104-6.707-4.859L4.987,252.704 C-1.638,257.948-1.666,266.489,4.925,271.773z"></path>
            <path d="M255.845,271.773l263.77,216.474c6.591,5.288,9.593,3.137,6.707-4.807l-69.104-206.862 c-2.889-7.94-2.861-20.809,0.061-28.736l68.981-203.849c2.923-7.929-0.082-10.104-6.707-4.859l-263.647,213.57 C249.282,257.948,249.254,266.489,255.845,271.773z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

IcDoubleRight.defaultProps = {
  size: 12
}

export default IcDoubleRight;

