import { selectQuestionsWithWeights } from "./reservoir-sampling";

export const aiSelectFromPrevAnswer = (questions: Array<{
  hand_number: number;
  last_result?: boolean;
}>, except_hand_numbers: number[]) => {
  const selected_hand_numbers = selectQuestionsWithWeights(
    questions.filter(({ hand_number }) => !except_hand_numbers.includes(hand_number)).map((question) => {
      return {
        hand_number: question.hand_number,
        weight: question.last_result === true ? 10 : (question.last_result === false ? 45 : 45)
      }
    }),
    1
  );
  return selected_hand_numbers;
}