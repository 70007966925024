import classNames from 'classnames';
import styles from './email-subscriber.module.scss';
import { IcSend } from '@clc-v2/icons';
import { useState } from 'react';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface EmailSubscriberProps {
  onSendHandler: (email: string) => Promise<boolean>;
}

export function EmailSubscriber(props: EmailSubscriberProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const onSendHandler = lodash.debounce(() => {
    setLoading(true);
    props.onSendHandler(email).then(() => {
      setLoading(false);
    });
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'flex items-center gap-3'
    )}>
      <input className='h-14 rounded-full bg-transparent border border-solid border-primary-golden placeholder:text-secondary-grey text-white font-normal px-5 outline-none' type="email" placeholder='/type e-mail & join our newsletter' disabled={loading} />
      <button className='w-14 h-14 rounded-full flex justify-center items-center border border-solid border-primary-golden' disabled={loading} onClick={onSendHandler}>
        <IcSend />
      </button>
    </div>
  );
}

export default EmailSubscriber;
