import { TooltipProps } from "recharts";

export interface ICustomTooltipProps {
    payload: {
        chartType?: string;
        color: string; // "rgba(232, 186, 115, 1)"
        dataKey: string; // "Questions Correct"
        fill: string; // "rgba(232, 186, 115, 1)"
        formatter?: string;
        name: string; // "Questions Correct"
        payload: Record<string, string | number>;
        // {
        //     "Questions Correct": 3
        //     "Tickets Earned": 3
        //     xLabel: "Tuesday"
        // }
        type?: string;
        unit: string;
        value: number
    };
    label: string;
    active: boolean;
}

export function CustomTooltip({ payload, label, active }: TooltipProps<string, number>) {
    if (active) {
        return (
            <div className="min-w-36 h-24 rounded-xl relative bg-primary-carbon font-inter flex flex-col justify-center items-center gap-1 text-sm px-4">
                <p className="text-white font-semibold ">{label}</p>
                <div className="flex items-center gap-2">
                    <span className="text-white">{payload?.[0]?.["name"]}:</span>
                    <span className="text-primary-golden">{payload?.[0]?.["value"]}</span>
                </div>
                <div className="flex items-center gap-2">
                    <span className="text-white">{payload?.[1]?.["name"]}:</span>
                    <span className="text-[#759A47]">{payload?.[1]?.["value"]}</span>
                </div>
            </div>
        );
    }

    return null;
}
