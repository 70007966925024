import two_clubs from '../assets/images/cards_v1/2_clubs.svg';
import two_diamonds from '../assets/images/cards_v1/2_diamonds.svg';
import two_hearts from '../assets/images/cards_v1/2_hearts.svg';
import two_spades from '../assets/images/cards_v1/2_spades.svg';
import three_clubs from '../assets/images/cards_v1/3_clubs.svg';
import three_diamonds from '../assets/images/cards_v1/3_diamonds.svg';
import three_hearts from '../assets/images/cards_v1/3_hearts.svg';
import three_spades from '../assets/images/cards_v1/3_spades.svg';
import four_clubs from '../assets/images/cards_v1/4_clubs.svg';
import four_diamonds from '../assets/images/cards_v1/4_diamonds.svg';
import four_hearts from '../assets/images/cards_v1/4_hearts.svg';
import four_spades from '../assets/images/cards_v1/4_spades.svg';
import five_clubs from '../assets/images/cards_v1/5_clubs.svg';
import five_diamonds from '../assets/images/cards_v1/5_diamonds.svg';
import five_hearts from '../assets/images/cards_v1/5_hearts.svg';
import five_spades from '../assets/images/cards_v1/5_spades.svg';
import six_clubs from '../assets/images/cards_v1/6_clubs.svg';
import six_diamonds from '../assets/images/cards_v1/6_diamonds.svg';
import six_hearts from '../assets/images/cards_v1/6_hearts.svg';
import six_spades from '../assets/images/cards_v1/6_spades.svg';
import seven_clubs from '../assets/images/cards_v1/7_clubs.svg';
import seven_diamonds from '../assets/images/cards_v1/7_diamonds.svg';
import seven_hearts from '../assets/images/cards_v1/7_hearts.svg';
import seven_spades from '../assets/images/cards_v1/7_spades.svg';
import eight_clubs from '../assets/images/cards_v1/8_clubs.svg';
import eight_diamonds from '../assets/images/cards_v1/8_diamonds.svg';
import eight_hearts from '../assets/images/cards_v1/8_hearts.svg';
import eight_spades from '../assets/images/cards_v1/8_spades.svg';
import nine_clubs from '../assets/images/cards_v1/9_clubs.svg';
import nine_diamonds from '../assets/images/cards_v1/9_diamonds.svg';
import nine_hearts from '../assets/images/cards_v1/9_hearts.svg';
import nine_spades from '../assets/images/cards_v1/9_spades.svg';
import ten_clubs from '../assets/images/cards_v1/10_clubs.svg';
import ten_diamonds from '../assets/images/cards_v1/10_diamonds.svg';
import ten_hearts from '../assets/images/cards_v1/10_hearts.svg';
import ten_spades from '../assets/images/cards_v1/10_spades.svg';
import a_clubs from '../assets/images/cards_v1/A_clubs.svg';
import a_diamonds from '../assets/images/cards_v1/A_diamonds.svg';
import a_hearts from '../assets/images/cards_v1/A_hearts.svg';
import a_spades from '../assets/images/cards_v1/A_spades.svg';
import k_clubs from '../assets/images/cards_v1/K_clubs.svg';
import k_diamonds from '../assets/images/cards_v1/K_diamonds.svg';
import k_hearts from '../assets/images/cards_v1/K_hearts.svg';
import k_spades from '../assets/images/cards_v1/K_spades.svg';
import q_clubs from '../assets/images/cards_v1/Q_clubs.svg';
import q_diamonds from '../assets/images/cards_v1/Q_diamonds.svg';
import q_hearts from '../assets/images/cards_v1/Q_hearts.svg';
import q_spades from '../assets/images/cards_v1/Q_spades.svg';
import j_clubs from '../assets/images/cards_v1/J_clubs.svg';
import j_diamonds from '../assets/images/cards_v1/J_diamonds.svg';
import j_hearts from '../assets/images/cards_v1/J_hearts.svg';
import j_spades from '../assets/images/cards_v1/J_spades.svg';


import two_clubs_v2 from '../assets/images/cards_v2/2_clubs.png';
import two_diamonds_v2 from '../assets/images/cards_v2/2_diamonds.png';
import two_hearts_v2 from '../assets/images/cards_v2/2_hearts.png';
import two_spades_v2 from '../assets/images/cards_v2/2_spades.png';
import three_clubs_v2 from '../assets/images/cards_v2/3_clubs.png';
import three_diamonds_v2 from '../assets/images/cards_v2/3_diamonds.png';
import three_hearts_v2 from '../assets/images/cards_v2/3_hearts.png';
import three_spades_v2 from '../assets/images/cards_v2/3_spades.png';
import four_clubs_v2 from '../assets/images/cards_v2/4_clubs.png';
import four_diamonds_v2 from '../assets/images/cards_v2/4_diamonds.png';
import four_hearts_v2 from '../assets/images/cards_v2/4_hearts.png';
import four_spades_v2 from '../assets/images/cards_v2/4_spades.png';
import five_clubs_v2 from '../assets/images/cards_v2/5_clubs.png';
import five_diamonds_v2 from '../assets/images/cards_v2/5_diamonds.png';
import five_hearts_v2 from '../assets/images/cards_v2/5_hearts.png';
import five_spades_v2 from '../assets/images/cards_v2/5_spades.png';
import six_clubs_v2 from '../assets/images/cards_v2/6_clubs.png';
import six_diamonds_v2 from '../assets/images/cards_v2/6_diamonds.png';
import six_hearts_v2 from '../assets/images/cards_v2/6_hearts.png';
import six_spades_v2 from '../assets/images/cards_v2/6_spades.png';
import seven_clubs_v2 from '../assets/images/cards_v2/7_clubs.png';
import seven_diamonds_v2 from '../assets/images/cards_v2/7_diamonds.png';
import seven_hearts_v2 from '../assets/images/cards_v2/7_hearts.png';
import seven_spades_v2 from '../assets/images/cards_v2/7_spades.png';
import eight_clubs_v2 from '../assets/images/cards_v2/8_clubs.png';
import eight_diamonds_v2 from '../assets/images/cards_v2/8_diamonds.png';
import eight_hearts_v2 from '../assets/images/cards_v2/8_hearts.png';
import eight_spades_v2 from '../assets/images/cards_v2/8_spades.png';
import nine_clubs_v2 from '../assets/images/cards_v2/9_clubs.png';
import nine_diamonds_v2 from '../assets/images/cards_v2/9_diamonds.png';
import nine_hearts_v2 from '../assets/images/cards_v2/9_hearts.png';
import nine_spades_v2 from '../assets/images/cards_v2/9_spades.png';
import ten_clubs_v2 from '../assets/images/cards_v2/10_clubs.png';
import ten_diamonds_v2 from '../assets/images/cards_v2/10_diamonds.png';
import ten_hearts_v2 from '../assets/images/cards_v2/10_hearts.png';
import ten_spades_v2 from '../assets/images/cards_v2/10_spades.png';
import a_clubs_v2 from '../assets/images/cards_v2/A_clubs.png';
import a_diamonds_v2 from '../assets/images/cards_v2/A_diamonds.png';
import a_hearts_v2 from '../assets/images/cards_v2/A_hearts.png';
import a_spades_v2 from '../assets/images/cards_v2/A_spades.png';
import k_clubs_v2 from '../assets/images/cards_v2/K_clubs.png';
import k_diamonds_v2 from '../assets/images/cards_v2/K_diamonds.png';
import k_hearts_v2 from '../assets/images/cards_v2/K_hearts.png';
import k_spades_v2 from '../assets/images/cards_v2/K_spades.png';
import q_clubs_v2 from '../assets/images/cards_v2/Q_clubs.png';
import q_diamonds_v2 from '../assets/images/cards_v2/Q_diamonds.png';
import q_hearts_v2 from '../assets/images/cards_v2/Q_hearts.png';
import q_spades_v2 from '../assets/images/cards_v2/Q_spades.png';
import j_clubs_v2 from '../assets/images/cards_v2/J_clubs.png';
import j_diamonds_v2 from '../assets/images/cards_v2/J_diamonds.png';
import j_hearts_v2 from '../assets/images/cards_v2/J_hearts.png';
import j_spades_v2 from '../assets/images/cards_v2/J_spades.png';
import giphy from '../assets/images/giphy.gif';

export const renderCard = (value: string, version?: string) => {
    switch (version) {
        case "v2":
            switch (value) {
                case '2c':
                    return two_clubs_v2;
                case '2d':
                    return two_diamonds_v2;
                case '2h':
                    return two_hearts_v2;
                case '2s':
                    return two_spades_v2;
                case '3c':
                    return three_clubs_v2;
                case '3d':
                    return three_diamonds_v2;
                case '3h':
                    return three_hearts_v2;
                case '3s':
                    return three_spades_v2;
                case '4c':
                    return four_clubs_v2;
                case '4d':
                    return four_diamonds_v2;
                case '4h':
                    return four_hearts_v2;
                case '4s':
                    return four_spades_v2;
                case '5c':
                    return five_clubs_v2;
                case '5d':
                    return five_diamonds_v2;
                case '5h':
                    return five_hearts_v2;
                case '5s':
                    return five_spades_v2;
                case '6c':
                    return six_clubs_v2;
                case '6d':
                    return six_diamonds_v2;
                case '6h':
                    return six_hearts_v2;
                case '6s':
                    return six_spades_v2;
                case '7c':
                    return seven_clubs_v2;
                case '7d':
                    return seven_diamonds_v2;
                case '7h':
                    return seven_hearts_v2;
                case '7s':
                    return seven_spades_v2;
                case '8c':
                    return eight_clubs_v2;
                case '8d':
                    return eight_diamonds_v2;
                case '8h':
                    return eight_hearts_v2;
                case '8s':
                    return eight_spades_v2;
                case '9c':
                    return nine_clubs_v2;
                case '9d':
                    return nine_diamonds_v2;
                case '9h':
                    return nine_hearts_v2;
                case '9s':
                    return nine_spades_v2;
                case 'Tc':
                    return ten_clubs_v2;
                case 'Td':
                    return ten_diamonds_v2;
                case 'Th':
                    return ten_hearts_v2;
                case 'Ts':
                    return ten_spades_v2;
                case 'Ac':
                    return a_clubs_v2;
                case 'Ad':
                    return a_diamonds_v2;
                case 'Ah':
                    return a_hearts_v2;
                case 'As':
                    return a_spades_v2;
                case 'Kc':
                    return k_clubs_v2;
                case 'Kd':
                    return k_diamonds_v2;
                case 'Kh':
                    return k_hearts_v2;
                case 'Ks':
                    return k_spades_v2;
                case 'Qc':
                    return q_clubs_v2;
                case 'Qd':
                    return q_diamonds_v2;
                case 'Qh':
                    return q_hearts_v2;
                case 'Qs':
                    return q_spades_v2;
                case 'Jc':
                    return j_clubs_v2;
                case 'Jd':
                    return j_diamonds_v2;
                case 'Jh':
                    return j_hearts_v2;
                case 'Js':
                    return j_spades_v2;
                default:
                    return giphy;
            }
        case "v1":
        default:
            switch (value) {
                case '2c':
                    return two_clubs;
                case '2d':
                    return two_diamonds;
                case '2h':
                    return two_hearts;
                case '2s':
                    return two_spades;
                case '3c':
                    return three_clubs;
                case '3d':
                    return three_diamonds;
                case '3h':
                    return three_hearts;
                case '3s':
                    return three_spades;
                case '4c':
                    return four_clubs;
                case '4d':
                    return four_diamonds;
                case '4h':
                    return four_hearts;
                case '4s':
                    return four_spades;
                case '5c':
                    return five_clubs;
                case '5d':
                    return five_diamonds;
                case '5h':
                    return five_hearts;
                case '5s':
                    return five_spades;
                case '6c':
                    return six_clubs;
                case '6d':
                    return six_diamonds;
                case '6h':
                    return six_hearts;
                case '6s':
                    return six_spades;
                case '7c':
                    return seven_clubs;
                case '7d':
                    return seven_diamonds;
                case '7h':
                    return seven_hearts;
                case '7s':
                    return seven_spades;
                case '8c':
                    return eight_clubs;
                case '8d':
                    return eight_diamonds;
                case '8h':
                    return eight_hearts;
                case '8s':
                    return eight_spades;
                case '9c':
                    return nine_clubs;
                case '9d':
                    return nine_diamonds;
                case '9h':
                    return nine_hearts;
                case '9s':
                    return nine_spades;
                case 'Tc':
                    return ten_clubs;
                case 'Td':
                    return ten_diamonds;
                case 'Th':
                    return ten_hearts;
                case 'Ts':
                    return ten_spades;
                case 'Ac':
                    return a_clubs;
                case 'Ad':
                    return a_diamonds;
                case 'Ah':
                    return a_hearts;
                case 'As':
                    return a_spades;
                case 'Kc':
                    return k_clubs;
                case 'Kd':
                    return k_diamonds;
                case 'Kh':
                    return k_hearts;
                case 'Ks':
                    return k_spades;
                case 'Qc':
                    return q_clubs;
                case 'Qd':
                    return q_diamonds;
                case 'Qh':
                    return q_hearts;
                case 'Qs':
                    return q_spades;
                case 'Jc':
                    return j_clubs;
                case 'Jd':
                    return j_diamonds;
                case 'Jh':
                    return j_hearts;
                case 'Js':
                    return j_spades;
                default:
                    return giphy;
            }
    }
};