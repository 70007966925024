import styles from './ic-play-2.module.scss';
import png from './play.png';

/* eslint-disable-next-line */
export interface IcPlay2Props {
  size: number;
}

export function IcPlay2(props: IcPlay2Props) {
  return (
    <img className={styles['container']} width={props.size} height={props.size} src={png} />
  );
}

IcPlay2.defaultProps = {
  size: 18,
}

export default IcPlay2;
