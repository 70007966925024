import styles from './ic-chat.module.scss';

/* eslint-disable-next-line */
export interface IcChatProps {
  size: number;
}

export function IcChat(props: IcChatProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 39 / 51} viewBox="0 0 51 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_727_4673)">
      <path d="M46.7044 27.751C46.6365 27.7241 46.5648 27.6942 46.4889 27.6613C45.8954 27.4048 45.1616 27.0152 44.3933 26.6047C44.3598 26.5867 44.3258 26.5686 44.2918 26.5504C43.5689 26.1638 42.8222 25.7646 42.1917 25.4811C41.8622 25.3329 41.5424 25.206 41.2593 25.1293C41.0143 25.0628 40.653 24.9908 40.3299 25.1154C38.2366 25.9228 35.8805 26.3777 33.3858 26.3777C24.5202 26.3777 17.5908 20.6973 17.5908 13.9736C17.5908 7.24993 24.5202 1.56946 33.3858 1.56946C42.2514 1.56946 49.1808 7.24993 49.1808 13.9736C49.1808 17.0218 47.7754 19.8357 45.4002 22.0205C45.0804 22.3143 45.0763 22.7292 45.1004 22.9719C45.128 23.2463 45.2167 23.5489 45.3224 23.8416C45.504 24.3445 45.7811 24.9368 46.0551 25.5119L46.7044 27.751Z" stroke="white" strokeWidth="3.26711"/>
      <path d="M4.05451 35.0534L4.67256 32.9689C4.94003 32.3757 5.21036 31.7681 5.39017 31.261C5.49206 30.9736 5.57816 30.6828 5.6112 30.4258C5.62769 30.2971 5.63509 30.1474 5.60927 29.9964C5.58326 29.8442 5.51527 29.6469 5.34377 29.4887C2.97808 27.3061 1.5791 24.498 1.5791 21.4566C1.5791 14.7329 8.50854 9.05249 17.3741 9.05249C26.2395 9.05249 33.169 14.7329 33.169 21.4566C33.169 28.1803 26.2395 33.8608 17.3741 33.8608C14.5869 33.8608 11.973 33.293 9.70541 32.3005C9.52002 32.2193 9.33474 32.2151 9.19503 32.2281C9.05078 32.2416 8.90767 32.2779 8.77562 32.3214C8.51216 32.4082 8.21835 32.5483 7.92099 32.7081C7.34231 33.0191 6.65658 33.4564 5.99746 33.8767L5.93509 33.9164C5.24317 34.3576 4.58521 34.7751 4.05451 35.0534Z" fill="#F7F9FA" stroke="white" strokeWidth="3.26711"/>
      <path d="M10.2794 21.4591C10.2794 21.9829 10.7031 22.4076 11.2257 22.4076C11.7482 22.4076 12.1719 21.9829 12.1719 21.4591C12.1719 20.9352 11.7482 20.5105 11.2257 20.5105C10.7031 20.5105 10.2794 20.9352 10.2794 21.4591Z" fill="#393EA0"/>
      <path d="M16.4279 21.4591C16.4279 21.9829 16.8515 22.4076 17.3741 22.4076C17.8967 22.4076 18.3203 21.9829 18.3203 21.4591C18.3203 20.9352 17.8967 20.5105 17.3741 20.5105C16.8515 20.5105 16.4279 20.9352 16.4279 21.4591Z" fill="#393EA0"/>
      <path d="M22.5734 21.4591C22.5734 21.9829 22.997 22.4076 23.5196 22.4076C24.0422 22.4076 24.4658 21.9829 24.4658 21.4591C24.4658 20.9352 24.0422 20.5105 23.5196 20.5105C22.997 20.5105 22.5734 20.9352 22.5734 21.4591Z" fill="#393EA0"/>
      </g>
      <defs>
      <clipPath id="clip0_727_4673">
      <rect width="51" height="39" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}

IcChat.defaultProps = {
  size: 51
}

export default IcChat;
