import moment from "moment";
export const dateFormatter = (date: string, format?: string) => {
  if (date === '') return null
  if (!format) format = 'YYYY-MM-DD';
  const formats = [
    'YYYY-MM-DD', 'YYYY/MM/DD',
    'MM-DD-YYYY', 'MM/DD/YYYY',
    'DD-MM-YYYY', 'DD/MM/YYYY',
    // 26/03/2024,
    // 2023-12-07
  ]
  try {
    let result = '';
    let i = 0;
    do {
      if (formats[i]) {
        result = moment(date, formats[i]).format(format);
        i++
      } else {
        break;
      }
    } while (result === 'Invalid date');
    return result
  } catch (err) {
    console.error(err);
    return date
  }
}