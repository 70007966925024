import classNames from 'classnames';
import styles from './progress-chart.module.scss';
import { Bar, XAxis, YAxis, Area, Legend, Tooltip, ComposedChart, TooltipProps } from 'recharts';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { CustomTooltip, ICustomTooltipProps } from './custom-tooltip';
import { IcCircleIcon, IcRectangleIcon } from '@clc-v2/icons';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

export interface IProgressChartDisplay {
  date: string; // MM-DD-YYYY
  ticket: number;
  question: number;
}

/* eslint-disable-next-line */
export interface ProgressChartProps {
  mode: 'month' | 'week';
  data: Array<IProgressChartDisplay>;
}

export function ProgressChart(props: ProgressChartProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [displayData, setDisplayData] = useState<Array<{
    xLabel: string;
    'Tickets Earned': number;
    'Questions Correct': number;
  }>>([]);
  const [max_correct, set_max_correct] = useState<number>(0);

  useEffect(() => {
    setWidth((divRef.current?.offsetWidth || 0) > 680 ? (divRef.current?.offsetWidth || 0) : 680)
  }, []);

  useEffect(() => {
    setDisplayData(() => props.data.map(({ date, ticket, question }) => ({
      xLabel: props.mode === 'month' ? moment(date).format('Do') : props.mode === 'week' ? moment(date).format('dddd') : (date),
      'Questions Correct': question,
      'Tickets Earned': ticket
    })));
    const max_correct = props.data.reduce((a, b) => a > b.question ? a : b.question, 0)
    set_max_correct(max_correct);
  }, [props.mode, props.data]);

  return (
    <div ref={divRef} className={classNames(
      styles['container'],
      'w-full h-[485px]',
      'overflow-auto',
      'bg-gradient-to-b from-linear-graident-4 to-card-glow-fill',
      'flex items-center',
      'rounded-small'
    )}>
      <ComposedChart
        width={width}
        height={440}
        data={displayData}
      >
        <defs>
          <linearGradient id={`gradient-chart-graph`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={"rgba(117, 154, 71)"} stopOpacity={1}></stop>
            <stop offset="100%" stopColor={"rgba(117, 154, 71)"} stopOpacity={0.05}></stop>
          </linearGradient>
        </defs>

        <XAxis dataKey="xLabel" interval={props.mode === 'month' ? 4 : 0} />
        <YAxis yAxisId="left" /> {/* ticks={new Array(4).fill(true).map((_, id) => max_correct * id / 2)} */}
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip content={(props: TooltipProps<string, number>) => <CustomTooltip {...props} />} />
        {/* <Legend formatter={(value, entry, index) => (<span className='text-white text-xs leading-[17px] font-inter font-normal'>{value}</span>)} /> */}
        <Bar yAxisId="right" dataKey="Tickets Earned" barSize={width / 3 / Math.max(displayData.length, 1)} fill='rgba(232, 186, 115, 1)' radius={[8, 8, 0, 0]} />
        <Area yAxisId="left" dataKey="Questions Correct" stroke="rgba(117, 154, 71, 1)" fill="url(#gradient-chart-graph)" dot={{ r: 4 }} activeDot={{ r: 8 }} />
        <Legend content={(props) => <CustomLegend payload={props.payload} />} />
      </ComposedChart>
    </div>
  );
}

interface CustomLegendProps {
  payload: Payload[] | undefined
}

const CustomLegend: React.FC<CustomLegendProps> = ({ payload }) => {
  if (!payload) return null;

  return (
    <div className="flex items-center justify-center space-x-2">
      {payload.map((entry, index) => {
        const icon = entry.type === 'rect' ? <IcRectangleIcon /> : <IcCircleIcon />;

        return (
          <div key={`legend-${index}`} className="flex items-center space-x-1">
            <span>{icon}</span>
            <span className="text-white text-xs leading-[17px] font-inter font-normal">{entry.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressChart;
