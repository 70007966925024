export const aiWeightFromPercent = (understanding_percent?: number) => {
  if (understanding_percent === undefined) {
    return 30;
  } else if (understanding_percent < 25) {
    return 30;
  } else if (understanding_percent < 75) {
    return 40;
  } else if (understanding_percent < 90) {
    return 20;
  } else {
    return 10;
  }
}