import { Fragment, ReactNode } from 'react';
import styles from './bread-crumbs.module.scss';
import classNames from 'classnames';
import { IcRight } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface BreadCrumbsProps {
  menu: Array<{
    content: ReactNode;
    on_click?: () => void;
  }>
}

export function BreadCrumbs(props: BreadCrumbsProps) {
  return (
    <div className={classNames(
      styles['container'],
      "border-l-4 border-primary-golden",
      "flex flex-wrap items-center gap-0 h-[50px]",
      "pl-4"
    )}>
      {props.menu.map((item, id) => (
        <Fragment key={id}>
          <div
            className={classNames(
              'px-1.5',
              {
                'cursor-pointer': !!item.on_click
              }
            )}
            onClick={item.on_click}
          >
            {item.content}
          </div>
          {props.menu.length - 1 > id && (
            <div className='grow-0 shrink-0 w-6 h-6 flex justify-center items-center'>
              <IcRight />
            </div>
          )}
        </Fragment>
      ))
      }
    </div >
  );
}

export default BreadCrumbs;
