import classNames from 'classnames';
import styles from './card-course.module.scss';
import { IcChat, IcTopRight } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface CardCourseProps {
  title: string;
  description: string;
  hoverText: string;
  btnLabel: string;
  btnOnClickHandler: () => void;
}

export function CardCourse(props: CardCourseProps) {
  return (
    <div
      className={classNames(
        styles['container'],
        'w-full',
        'rounded-small',
        'border border-color-2 border-solid hover:border-primary-golden',
        'bg-secondary-transparent-coal hover:bg-primary-golden',
        'p-10',
        'flex flex-col gap-5',
        {
          'cursor-pointer': !!props.btnOnClickHandler
        },
        'transition-all',
        'group'
      )}
      onClick={props.btnOnClickHandler}
      title={props.hoverText}
    >
      <IcChat />
      <div className='flex flex-col'>
        <h4 className='text-white font-medium text-xl leading-[24px] tracking-[-0.2px] font-inter group-hover:text-black'>{props.title}</h4>
        <p className='text-text-op70 font-normal text-lg leading-[27px] font-inter group-hover:text-black'>{props.description}</p>
      </div>
      <div className='w-fit flex items-center gap-2 mt-5 cursor-pointer'>
        <span className='text-white font-normal text-lg leading-[27px] font-inter group-hover:text-black'>{props.btnLabel}</span>
        <IcTopRight />
      </div>
    </div>
  );
}

export default CardCourse;
