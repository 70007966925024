import styles from './ic-top-right.module.scss';

/* eslint-disable-next-line */
export interface IcTopRightProps {
  size: number;
}

export function IcTopRight(props: IcTopRightProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 17 / 16} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_780_110)">
      <path d="M6.33317 3.16663V3.83329H12.1932L2.6665 13.36L3.13984 13.8333L12.6665 4.30663V10.1666H13.3332V3.16663H6.33317Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_780_110">
      <rect width="10.6667" height="10.6667" fill="white" transform="translate(2.6665 3.16663)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

IcTopRight.defaultProps = {
  size: 16,
}

export default IcTopRight;
