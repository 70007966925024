import { useState } from 'react';
import styles from './login-email-form.module.scss';
import classNames from 'classnames';
import lodash from 'lodash';
import InputIcon from '../input-icon/input-icon';
import { IcEmail, IcPassword } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface LoginEmailFormProps {
  login_handler: (param: {
    email: string,
    password: string,
    remember_me: boolean,
  }) => Promise<boolean>;
  forgot_passwordHandler: () => void;
}

export function LoginEmailForm(props: LoginEmailFormProps) {
  const [loading, set_loading] = useState<boolean>(false);
  const [email, set_email] = useState<string>('');
  const [password, set_password] = useState<string>('');
  const [remember_me, set_remember_me] = useState<boolean>(false);
  const [err_msgs, set_err_msgs] = useState<string[]>([]);
  const login_handler = lodash.debounce(() => {
    if (email && password) {
      set_err_msgs([]);
      set_loading(true);
      props.login_handler({
        email,
        password,
        remember_me,
      }).then((value) => {
      }).catch((msgs: string[]) => {
        set_err_msgs(msgs);
      }).finally(() => {
        set_loading(false);
      });
    }
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'flex flex-col gap-[10px]',
      'w-full',
    )}>
      <InputIcon prefix={<div className='grow-0 shrink-0 w-6 h-6'><IcEmail /></div>} type="email" placeholder='Email' value={email} onChange={(e) => set_email(e.target.value)} />
      <InputIcon prefix={<div className='grow-0 shrink-0 w-6 h-6'><IcPassword /></div>} type="password" placeholder='Password' value={password} onChange={(e) => set_password(e.target.value)} />
      <div className='flex justify-center items-center'>
        <span className='text-white cursor-pointer' onClick={props.forgot_passwordHandler}>
          Forgot password?
        </span>
      </div>
      {
        err_msgs.length > 0 && (
          <div className='relative break-words'>
            {err_msgs.slice(0, 3).map((msg, id) => <p className='text-text-op70' key={id}>{msg}</p>)}
          </div>
        )
      }
      <button className='w-full h-14 bg-black hover:bg-primary-golden transition-all border border-solid border-primary-golden text-white text-sm font-light rounded-full flex justify-center items-center tracking-widest' disabled={loading} onClick={login_handler}>
        {loading ? "LOGGING" : "LOGIN"}
      </button>
    </div>
  );
}

export default LoginEmailForm;
