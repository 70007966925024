export function selectQuestionsWithWeights(questions: Array<{
  weight: number; // 0 < weight < 100
  hand_number: number;
}>, k: number) {
  const sampledQuestions: Array<number> = [];
  const total = questions.length;
  if (total > k) {
    while (sampledQuestions.length < k) {
      const rand_index = Math.floor(total * Math.random());
      if (questions[rand_index].weight > Math.random() * 100) {
        if (!sampledQuestions.includes(questions[rand_index].hand_number)) {
          sampledQuestions.push(questions[rand_index].hand_number);
        }
      }
    }
    return sampledQuestions;
  } else {
    return questions.map(({ hand_number }) => hand_number);
  }
}

export function selectLessonsWithWeights(lessons: Array<{ lesson_uid: string; weight: number }>, k: number) {
  const sampledLessons: Array<string> = [];
  const total = lessons.length;
  while (sampledLessons.length < k) {
    let rand_index = Math.floor(total * Math.random());
    if (rand_index === total) {
      rand_index = total - 1;
    }
    const rand_weight = Math.random() * 100;
    if (lessons[rand_index].weight > rand_weight) {
      sampledLessons.push(lessons[rand_index].lesson_uid);
    }
  }
  return sampledLessons;
}
