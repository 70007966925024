import styles from './ic-send.module.scss';

/* eslint-disable-next-line */
export interface IcSendProps {
  size: number;
}

export function IcSend(props: IcSendProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 21 / 22} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.976835 0.25731C1.13367 0.121165 1.32736 0.0345363 1.53345 0.00837255C1.73953 -0.0177912 1.94875 0.0176846 2.13466 0.110316L21.0465 9.55995C21.2214 9.64703 21.3685 9.7811 21.4713 9.9471C21.5741 10.1131 21.6285 10.3044 21.6285 10.4997C21.6285 10.6949 21.5741 10.8862 21.4713 11.0522C21.3685 11.2182 21.2214 11.3523 21.0465 11.4394L2.13466 20.889C1.94876 20.982 1.73945 21.0177 1.53321 20.9918C1.32697 20.9658 1.13306 20.8793 0.976041 20.7432C0.819017 20.607 0.705929 20.4274 0.651085 20.2271C0.596241 20.0267 0.602108 19.8146 0.667941 19.6175L3.35973 11.5496H9.01964C9.29829 11.5496 9.56553 11.439 9.76257 11.2421C9.9596 11.0452 10.0703 10.7781 10.0703 10.4997C10.0703 10.2212 9.9596 9.95414 9.76257 9.75723C9.56553 9.56033 9.29829 9.44971 9.01964 9.44971H3.35973L0.666891 1.38182C0.601396 1.18486 0.595784 0.972917 0.650762 0.77277C0.705741 0.572624 0.819895 0.393251 0.976835 0.25731Z" fill="white"/>
    </svg>
  );
}

IcSend.defaultProps = {
  size: 22,
}

export default IcSend;
