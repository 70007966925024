import styles from './ic-close-2.module.scss';

/* eslint-disable-next-line */
export interface IcClose2Props {
  size: number;
}

export function IcClose2(props: IcClose2Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#ff0000" stroke="#ff0000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <title>close-circle-solid</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="icons_Q2" data-name="icons Q2">
            <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2Zm8.3,27.5a2.1,2.1,0,0,1,.4,2.7,2,2,0,0,1-3.1.2L24,26.8l-5.6,5.6a2,2,0,0,1-3.1-.2,2.1,2.1,0,0,1,.4-2.7L21.2,24l-5.5-5.5a2.2,2.2,0,0,1-.4-2.7,2,2,0,0,1,3.1-.2L24,21.2l5.6-5.6a2,2,0,0,1,3.1.2,2.2,2.2,0,0,1-.4,2.7L26.8,24Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

IcClose2.defaultProps = {
  size: 24
}

export default IcClose2;
