import styles from './ic-spotify.module.scss';

/* eslint-disable-next-line */
export interface IcSpotifyProps {
  size: number;
}

export function IcSpotify(props: IcSpotifyProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 18 / 16} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0671 7.87274C9.8533 6.25774 6.15215 6.08774 4.04214 6.89524C3.69624 7.02274 3.35034 6.76774 3.24657 6.38524C3.1428 5.96024 3.35034 5.53524 3.66165 5.40774C6.11756 4.51524 10.1646 4.68524 12.7243 6.55524C13.0356 6.76774 13.1394 7.27774 12.9664 7.66024C12.7935 7.95774 12.3784 8.08524 12.0671 7.87274ZM11.9979 10.2527C11.8249 10.5502 11.5136 10.6777 11.2715 10.4652C9.40363 9.06274 6.56723 8.63774 4.38804 9.48774C4.11132 9.57274 3.80001 9.40274 3.73083 9.06274C3.66165 8.72274 3.80001 8.34024 4.07673 8.25524C6.60182 7.32024 9.71494 7.78774 11.8595 9.40274C12.0671 9.53024 12.1708 9.95524 11.9979 10.2527ZM11.1677 12.5902C11.0294 12.8452 10.7872 12.9302 10.5797 12.7602C8.95395 11.5277 6.91313 11.2727 4.49181 11.9527C4.24968 12.0377 4.04214 11.8252 3.97296 11.5702C3.90378 11.2727 4.07673 11.0177 4.28427 10.9327C6.91313 10.2102 9.19608 10.5077 10.9948 11.8677C11.2369 11.9952 11.2715 12.3352 11.1677 12.5902ZM7.98543 0.307739C7.07694 0.307739 6.17734 0.527598 5.33801 0.954763C4.49867 1.38193 3.73603 2.00803 3.09363 2.79733C1.79625 4.39139 1.06738 6.5534 1.06738 8.80774C1.06738 11.0621 1.79625 13.2241 3.09363 14.8181C3.73603 15.6074 4.49867 16.2336 5.33801 16.6607C6.17734 17.0879 7.07694 17.3077 7.98543 17.3077C9.82021 17.3077 11.5798 16.4122 12.8772 14.8181C14.1746 13.2241 14.9035 11.0621 14.9035 8.80774C14.9035 7.6915 14.7245 6.5862 14.3769 5.55493C14.0292 4.52366 13.5196 3.58663 12.8772 2.79733C12.2348 2.00803 11.4722 1.38193 10.6328 0.954763C9.79351 0.527598 8.89392 0.307739 7.98543 0.307739Z" fill="white"/>
    </svg>
  );
}

IcSpotify.defaultProps = {
  size: 16,
}

export default IcSpotify;
