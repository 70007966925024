import classNames from 'classnames';
import styles from './card-cb-price.module.scss';
import Button from '../button/button';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { expired as expired_check } from '@clc-v2/utilities';
import { debounce } from 'lodash';

/* eslint-disable-next-line */
export interface CardCbPriceProps {
  item_price_id: string;
  price: number;
  currency_code: string;
  name: string;
  description: string;
  selected_or_upgrade?: boolean;
  scheduled: boolean;
  status?: string;
  trial_end?: number | null;
  next_billing_at?: number | null;
  canceled_at?: number | null;
  cancel_schedule_created_at?: number | null;
  self_description: string;
  monthly_price: number;
  monthly_price_full: number;
  saved_money_text: string;
  bill_text: string;
  tip: string;
  className?: string;
  force_new_purchase?: boolean;
  on_select?: (item_price_id: string, changing_item_price: boolean) => void;
  on_manage?: () => void;
  on_reactivate?: () => Promise<number>;
}

export function CardCbPrice(props: CardCbPriceProps) {
  const [expired, set_expired] = useState<boolean>(false);
  const [loading, set_loading] = useState<boolean>(false);
  const [show_refresh, set_show_refresh] = useState<boolean>(false);
  const reactivate_handler = debounce(() => {
    if (loading) return;
    set_loading(true);
    props.on_reactivate?.().then((_next_billing_at) => {
      set_show_refresh(true);
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_loading(false);
    });
  }, 300);
  useEffect(() => {
    const next_billing_at = props.next_billing_at;
    if (next_billing_at) {
      set_expired(expired_check(next_billing_at, 12 * 60));
    }
  }, [props.next_billing_at]);
  return (
    <div className={classNames(
      styles['container'],
      {
        "border-2 border-[#3D3D3D]": !props.tip.toLowerCase().includes('best') && !props.selected_or_upgrade,
        "border-8 border-[#1D7000]": props.selected_or_upgrade,
        "border-2 border-[#1D7000]": props.tip.toLowerCase().includes('best')
      },
      "hover:border-primary-golden",
      "flex flex-col justify-center items-start",
      "w-full h-[440px]",
      "relative",
      'rounded-2xl',
      'px-6 py-6',
      'mt-6',
      'group',
      'z-30',
      props.className,
    )}>
      {(props.selected_or_upgrade && props.next_billing_at !== undefined && props.next_billing_at !== null) ? (
        props.status === 'in_trial' ? (
          <span className='absolute left-1/2 top-2 transform -translate-x-1/2 text-white w-full text-center'>You are in free trial. {(props.cancel_schedule_created_at && props.canceled_at && props.cancel_schedule_created_at > props.canceled_at) ? `Schedule to cancel subscription on ${moment.unix(props.next_billing_at).format('MM-DD-YYYY')}` : `Your subscription will start on ${moment.unix(props.next_billing_at).format('MM-DD-YYYY')}`}</span>
        ) : (
          props.status === 'cancelled' ? (
            <span className='absolute left-1/2 top-2 transform -translate-x-1/2 text-white w-full text-center'>{props.canceled_at ? `Your subscription was cancelled on ${moment.unix(props.canceled_at).format('MM-DD-YYYY')}` : ''}</span>
          ) : (
            props.status === 'non_renewing' ? (
              <span className='absolute left-1/2 top-2 transform -translate-x-1/2 text-white w-full text-center'>Schedule to cancel subscription on {moment.unix(props.next_billing_at).format('MM-DD-YYYY')}</span>
            ) : (
              <span className='absolute left-1/2 top-2 transform -translate-x-1/2 text-white w-full text-center'>{!expired ? "Next renewal on" : "Expired on"} {moment.unix(props.next_billing_at).format('MM-DD-YYYY')}</span>
            )
          )
        )
      ) : null}
      {
        props.tip && (
          <div className={classNames(
            'absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 px-4 py-3 rounded-full text-white font-inter border-2 group-hover:border-primary-golden text-center',
            {
              'bg-[#1D7000] border-[#1D7000]': props.tip.toLowerCase().includes('best'),
              'bg-black border-[#3D3D3D]': !props.tip.toLowerCase().includes('best'),
            },
            'uppercase'
          )}>{props.tip}</div>
        )
      }
      <h2 className='w-full text-2xl font-bold font-inter text-white py-6'>{props.name.replace('USD ', '')}</h2>
      <p className='w-full text-third-grey text-sm font-normal font-inter'>{props.self_description}</p>
      <div className='h-32 my-6 flex flex-col gap-2'>
        <div className='w-full flex items-center'>
          {props.monthly_price_full ? (
            <div className='text-third-grey font-inter font-normal text-5xl shadow-md line-through mr-2'>${props.monthly_price_full}</div>
          ) : null}
          {props.monthly_price && (
            <div className='text-white font-inter text-5xl shadow-md'>
              <span className='font-semibold'>$</span>
              <span className='font-semibold'>{Math.floor(props.monthly_price)}</span>
              {props.monthly_price - Math.floor(props.monthly_price) > 0 && (
                <span className='text-base'>.{Math.round((props.monthly_price - Math.floor(props.monthly_price)) * 100)}</span>
              )}
              <span className='text-base'>/mo</span>
            </div>
          )}
        </div>
        {props.saved_money_text && <div className='w-fit px-4 py-3 bg-[#1D7000] text-white uppercase'>{props.saved_money_text}</div>}
        <p className='text-white text-sm font-inter font-normal'>{props.bill_text}</p>
      </div>
      <div className='w-full'>
        {
          props.status === 'cancelled' ? (
            <>
              <Button className='w-full' type="primary" onClick={reactivate_handler}>
                {loading ? "Reactivating" : "Reactivate"}
              </Button>
              {show_refresh ? <div className='w-full text-center text-gray-400 cursor-pointer' onClick={() => window.location.reload()}>Refresh</div> : null}
            </>
          ) : (
            (expired && !props.force_new_purchase) ? (
              <Button className='w-full' type='primary' onClick={() => props.status === "cancelled" ? undefined : props.on_manage?.()} >Manage Subscription</Button>
            ) : !props.selected_or_upgrade ? (
              <button className='w-full rounded py-4 bg-new-button text-black font-inter font-bold text-base' disabled={props.status === "cancelled"} onClick={() => props.on_select?.(props.item_price_id, false)} >Start 7-Day Free Trial</button>
            ) : (
              props.selected_or_upgrade ? (
                <Button className='w-full' type="secondary">
                  Picked
                </Button>
              ) : (
                <Button className='w-full' type="primary" onClick={() => props.on_select?.(props.item_price_id, true)}>
                  {props.scheduled ? "Scheduled" : "Update"}
                </Button>
              )
            )
          )
        }
      </div>
      <p className='w-full text-center text-white font-normal font-inter mt-4'>Access all features. Cancel anytime.</p>
    </div >
  );
}

export default CardCbPrice;
