import classNames from 'classnames';
import styles from './video-item.module.scss';
import { IcCheck, IcPlay, IcPlayCircle } from '@clc-v2/icons';
import { convertSecondsToReadable } from '@clc-v2/utilities';

/* eslint-disable-next-line */
export interface VideoItemProps {
  video: {
    title: string;
    video_duration: number;
  };
  active?: boolean;
  seen?: boolean;
  on_click?: () => void;
}

export function VideoItem(props: VideoItemProps) {
  return (
    <div className={classNames(
      styles['container'],
      "px-5 py-3",
      "flex justify-between items-center gap-2",
      {
        'text-white': !props.active,
        'text-black bg-primary-golden': props.active
      },
      'cursor-pointer',
      'border border-l-color-2 border-t-color-2 border-r-color-3 border-b-color-3'
    )} onClick={props.on_click}>
      <div>
        {(props.seen && !props.active) ? <IcCheck size={24} /> : <IcPlayCircle size={24} color={props.active ? 'black' : 'white'} />}
      </div>
      <p className='shrink grow text-lg font-normal font-inter leading-[27px]'>{props.video.title}</p>
      <span className='text-lg font-normal font-inter leading-[27px]'>{convertSecondsToReadable(props.video.video_duration)}</span>
    </div>
  );
}

export default VideoItem;
